import constate from 'constate'
import { useCallback } from 'react'
import { toast, ToastContent } from 'react-toastify'
import { TNotificationVariant } from '../type'

const ToastVariant: {[key in TNotificationVariant]:
  {
    background: string,
    color: string
  } } = {
    error: {
      background: '#FEE9E9',
      color: 'darkBlack'
    },
    warn: {
      background: 'orange',
      color: 'white'
    },
    success: {
      background: 'blue200',
      color: 'darkBlack'
    }
  }

function showToast(variant: TNotificationVariant) {
  return (content: ToastContent) => toast[variant](
    content,
    {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: false,
      draggable: false,
      pauseOnHover: false,
      style: {
        background: ToastVariant[variant].background.toString(),
        color: ToastVariant[variant].color.toString()
      }
    }
  )
}

const useNotification = () => ({
  showErrorNotification: useCallback(showToast('error'), []),
  showWarningNotification: useCallback(showToast('warn'), []),
  showSuccessNotification: useCallback(showToast('success'), [])
})

export const [NotificationProvider, useNotificationContext] = constate(useNotification)
