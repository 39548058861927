import { Column } from 'react-table'
import { FC } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { format } from 'date-fns'
import Table from '../../../shared/table/components/Table'
import { Feedback } from '../types'
import Attachments from './Attachments'

const TitleStyled = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  max-width: 172px;
  word-wrap: break-word;
`

const DescriptionStyled = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  max-width: 401px;
  word-wrap: break-word;
`

const TextStyled = styled.div<{ width?: number }>`
  width: ${({ width }) => width || 100}px;
  word-wrap: break-word;
`

const ModalGlobalStyles = createGlobalStyle`
  .MuiTableBody-root .MuiTableCell-root {
    vertical-align: baseline;
  }

  .MuiTableCell-head {
    padding-right: 16px !important;
  }

  .MuiTableCell-root.MuiTableCell-body {
    padding-right: 16px !important;
  }
`

function useColumns() {
  const columns: Column<any>[] = [
    {
      Header: () => <TextStyled width={20}>№</TextStyled>,
      accessor: 'number',
      Cell: ({ row }) => <span>{row.index + 1}</span>
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ value }) => <TitleStyled>{value}</TitleStyled>
    },
    {
      Header: () => <TextStyled>Attachments</TextStyled>,
      accessor: 'attachments',
      Cell: ({ value }) => ((value as number[])?.length ? <Attachments id={(value as number[])[0]} /> : <span>-</span>)
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value }) => <DescriptionStyled>{value}</DescriptionStyled>
    },
    {
      Header: () => <TextStyled width={75}>Page</TextStyled>,
      accessor: 'page',
      Cell: ({ value }) => <TextStyled width={75}>{value}</TextStyled>
    },
    {
      Header: <TextStyled width={70}>Date</TextStyled>,
      accessor: 'createdAt',
      Cell: ({ value }) => <TextStyled width={75}>{format(new Date(value), 'yyyy-MM-dd hh:mma')}</TextStyled>
    },
    {
      Header: () => <TextStyled>Full Name</TextStyled>,
      accessor: 'author.firstName',
      Cell: ({ row }) => {
        const rowOriginal = row.original as Feedback
        return <span>{`${rowOriginal.author.firstName} ${rowOriginal.author.lastName}`}</span>
      }
    },
    {
      Header: <TextStyled>E-mail</TextStyled>,
      accessor: 'author.email',
      Cell: ({ value }) => <TextStyled>{value}</TextStyled>
    }
  ]

  return columns
}

type Props = {
  tableData: Feedback[]
}
const FeedbackTable: FC<Props> = ({ tableData }) => (
  <>
    <ModalGlobalStyles />
    <Table data={tableData} columns={useColumns()} />
  </>
)

export default FeedbackTable
