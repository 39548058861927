import { FC, PropsWithChildren } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuthContext } from '../../../state/useAuth'
import { ROUTES } from '../../../constants/routes'

const ProtectedRoutesGuard: FC<PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useAuthContext().authData?.access_token

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN} />
  }

  return (
    children
      ? <>{children}</>
      : <Outlet />
  )
}

export default ProtectedRoutesGuard
