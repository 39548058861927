import { FC } from 'react'
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material'
import { useTheme } from 'styled-components'

type TVariant = 'primary' | 'outlined' | 'secondary' | 'critical'

type TButtonProps = Omit<ButtonProps, 'variant'>

type TMainProps = {
  variant?: TVariant,
  loading?: boolean
} & TButtonProps

const baseStyles = {
  fontSize: '14px',
  height: '36px',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none'
  }
}

const PrimaryButton: FC<TButtonProps> = ({
  sx,
  ...rest
}) => {
  const { colors } = useTheme()
  return (
    <MuiButton
      {...rest}
      variant="contained"
      sx={{
        ...baseStyles,
        backgroundColor: colors.blue.primary,
        ':hover': {
          backgroundColor: colors.blue.dark
        },
        ':disabled': {
          backgroundColor: colors.blue.primary,
          opacity: 0.5,
          color: colors.white
        },
        ...sx
      }}
    />
  )
}

const OutlinedButton: FC<TButtonProps> = ({ sx, ...rest }) => {
  const { colors } = useTheme()
  return (
    <MuiButton
      {...rest}
      variant="contained"
      sx={{
        ...baseStyles,
        backgroundColor: colors.white,
        color: colors.black,
        border: `1px solid ${colors.black}`,
        ':hover': {
          backgroundColor: colors.grey.light
        },
        ...sx
      }}
    />
  )
}

const SecondaryButton: FC<TButtonProps> = ({ sx, ...rest }) => {
  const { colors } = useTheme()
  return (
    <MuiButton
      {...rest}
      variant="contained"
      sx={{
        ...baseStyles,
        border: 'none',
        color: colors.black,
        backgroundColor: colors.grey.primary,
        ':disabled': {
          backgroundColor: colors.grey.light
        },
        ':hover': {
          backgroundColor: colors.grey.secondary
        },
        ...sx
      }}
    />
  )
}

const CriticalButton: FC<TButtonProps> = ({ sx, ...rest }) => {
  const { colors } = useTheme()
  return (
    <PrimaryButton
      {...rest}
      sx={{
        backgroundColor: colors.red.primary,
        '&:hover': {
          backgroundColor: `${colors.red.dark} !important`,
          boxShadow: 'none'
        },
        '&:disabled': {
          backgroundColor: colors.red.primary
        },
        ...sx
      }}
    />
  )
}

const COMPONENTS_MAP: Record<TVariant, FC<TButtonProps>> = {
  primary: PrimaryButton,
  outlined: OutlinedButton,
  secondary: SecondaryButton,
  critical: CriticalButton
}

const Button: FC<TMainProps> = ({
  variant, children, loading, ...rest
}) => {
  const Component = variant
    ? COMPONENTS_MAP[variant]
    : PrimaryButton
  return (
    <Component {...rest}>
      {children}
      {loading && (
        <CircularProgress
          disableShrink
          color="inherit"
          thickness={7}
          size="14px"
          sx={{
            marginLeft: '8px',
            animationDuration: '1s'
          }}
        />
      )}
    </Component>
  )
}

export default Button
