import { FC } from 'react'
import { DialogActions, DialogActionsProps } from '@mui/material'
import { useTheme } from 'styled-components'

const ModalActions: FC<DialogActionsProps> = ({ sx, ...rest }) => {
  const { colors } = useTheme()

  return (
    <DialogActions
      {...rest}
      sx={{
        borderTop: `1px solid ${colors.grey.primary}`,
        padding: '16px 20px',
        display: 'grid',
        gridAutoFlow: 'column',
        gridColumnGap: '12px',
        '& button': {
          margin: '0 !important'
        },
        ...sx
      }}
    />
  )
}

export default ModalActions
