import styled from 'styled-components'
import { useMemo } from 'react'
import { Card } from '@mui/material'
import FeedbackTable from './FeedbackTable'
import { useFeedbackQuery } from '../queries'
import Spinner from '../../../shared/Spinner'

const WrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const HeaderStyled = styled.div`
  margin-bottom: 20px;
`

const TitleStyled = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
`

const CardStyled = styled(Card)`
  padding: 20px 20px 30px 20px;
  min-width: 1136px;
  height: 100%;
  width: 100%;
`

const SpinnerStyled = styled(Spinner)`
  margin-top: 20px;
`
const NoDataTextStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
`

const FeedbackManagement = () => {
  const { data, isLoading } = useFeedbackQuery()
  const tableRows = useMemo(() => data ?? [], [data])

  return (
    <WrapperStyled>
      <CardStyled>
        <HeaderStyled>
          <TitleStyled>Help & Feedback</TitleStyled>
        </HeaderStyled>
        {isLoading && data === undefined ? <SpinnerStyled /> : <FeedbackTable tableData={tableRows} />}
        {!isLoading && !data?.length && (
          <NoDataTextStyled>
            <TitleStyled>No Feedback</TitleStyled>
          </NoDataTextStyled>
        )}
      </CardStyled>
    </WrapperStyled>
  )
}

export default FeedbackManagement
