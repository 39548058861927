import {
  TDataset, TFilter, TFilterShortName, TRowData, TFiltersState, TKeywordData, TFilterDatasetsRequestKeyword
} from './definitions'
import { FILTER_GROUPS } from './constants'
import { TOption } from '../../shared/select/Select'
import googleAnalytic from '../googleAnalytic/utils'

const prepareVersionsToDisplay = (versions: string[], keywords?: TKeywordData[]): TRowData[] => (
  versions
    .sort((a, b) => +b - +a)
    .map((version) => ({
      name: version,
      keywords: keywords?.filter(({ versions }) => versions.find(({ year }) => year === version))
    }))
)

const sortDatasetsByName = (a: TDataset, b: TDataset) => a.name.localeCompare(b.name)
const sortDatasetsByKeywords = (a: TDataset, b: TDataset) => (b.keywords?.length ?? 0) - (a.keywords?.length ?? 0)

export const prepareDatasetsToDisplay = (datasets: TDataset[], sortBy: 'name' | 'keywords'): TRowData[] => (
  datasets
    .sort(sortBy === 'name' ? sortDatasetsByName : sortDatasetsByKeywords)
    .map((dataset) => ({
      id: dataset.id,
      name: dataset.name,
      short_name: dataset.short_name,
      keywords: dataset.keywords,
      subRows: prepareVersionsToDisplay(dataset.versions, dataset.keywords)
    }))
)

export const divideFiltersByGroups = (filters: TFilter[]) => (
  FILTER_GROUPS
    .map((group) => group
      .map(({ shortName }) => filters.find((filter) => filter.shortName === shortName) ?? null)
      .filter((item) => item !== null))
    .filter((group) => !!group.length) as TFilter[][]
)

export const filterVariants = (variants: string[], filterValue: string) => (
  variants.filter((variant) => variant.toLowerCase()
    .includes(filterValue.toLowerCase()))
)

export const prepareFiltersForSubmit = (filtersState: TFiltersState, filters: TFilter[]): TFilter[] => (
  Object
    .entries(filtersState)
    .filter(([, values]) => !!values?.filters?.length)
    .map(([shortName, values]) => ({
      name: filters.find((filter) => filter.shortName === shortName)?.name ?? '',
      shortName: shortName as TFilterShortName,
      value: values.filters,
      logic: values?.logic
    }))
)

export const splitKeywords = (keywords: string): TFilterDatasetsRequestKeyword[] => {
  const symbolsList = '"\'“”«»'

  const matchResult = keywords
    .match(/\w+|"[^"]+"|'[^']+'|“[^”]+”|“[^“]+“|”[^”]+”|«[^»]+»/g) || []
    .map((match) => String(match))

  return (
    matchResult.map(
      (item) => ({
        name: item,
        isContainQuoteMarks: symbolsList
          .split('')
          .some((symbol) => item.includes(symbol))
      })
    )
      .map((item) => ({
        ...item,
        name: item.name.replace(new RegExp(`[${symbolsList}]+`, 'g'), '')
      }))
  )
}

export const getVariablesIdsFromRows = (rows: TRowData[], keyword: string): number[] => (
  rows.reduce(
    (acc: number[], row: TRowData) => {
      const keywordData = row.keywords?.find(({ name }) => name === keyword)
      if (!keywordData) return acc
      const variablesIds = keywordData.versions.reduce(
        (acc, version) => [...acc, ...version.variablesId],
        [] as number[]
      )
      return [...acc, ...variablesIds]
    },
    [] as number[]
  )
)

export const isDateFilter = (shortName: TFilterShortName) => (
  ['data_available_years', 'metadata_included_years'].includes(shortName)
)

export const isLogicFilter = (shortName: TFilterShortName) => (
  ['data_available_years', 'metadata_included_years', 'record_level', 'geo_variables', 'host_organizations', 'mechanism'].includes(shortName)
)

export const getOptionsByYears = (years: string[]): TOption[] => years.map((year) => ({
  label: year,
  value: year
}))

export const filterVariantsByRange = (variants: string[], [from, to]: string[]) => (
  variants.filter((variant) => variant >= from && variant <= to)
)

export const trackKeywords = (keywords: string) => {
  if (!keywords.trim()) return
  splitKeywords(keywords).forEach(
    (keyword) => {
      googleAnalytic.trackEvent('search_by_keywords', { keyword })
    }
  )
}

export const trackDatasets = (datasets: TDataset[]) => {
  datasets.forEach((dataset) => {
    googleAnalytic.trackEvent(
      'show_dataset',
      { dataset_name: dataset.name, dataset_id: dataset.id }
    )
  })
}

export const trackFilter = (name: string, values: string[]) => {
  values.forEach((value) => {
    googleAnalytic.trackEvent(
      'apply_filter',
      { filter_name: name, filter_value: value }
    )
  })
}
