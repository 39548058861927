import { CellProps, Column } from 'react-table'
import { useNavigate } from 'react-router-dom'
import { FC, memo, useMemo } from 'react'
import styled from 'styled-components'
import StripedTable from '../../../shared/stripedTable/StripedTable'
import { CompareButton } from '../../compareButton/CompareButton'
import { useOpenDataset } from '../../../../hooks/useOpenDataset'
import Link from '../../../shared/Link'
import VariableSelectCell from './VariableSelectCell'
import { useComparedVariablesContext } from '../../valuesComparison/state/useComparedVariablesState'
import { ROUTES } from '../../../../constants/routes'
import Button from '../../../shared/Button'
import VariableCodeCell from './VariableCodeCell'
import TextHighlighter from '../../../shared/TextHighlighter'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'
import { prepareDatasetsForTable } from '../utils'
import Hint from '../../../shared/hint/Hint'
import { TRowData } from '../../valuesComparison/definitions'
import { getPrevRowIndex } from '../../../../utils/table'

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const ButtonWrapStyled = styled.div`
  display: flex;
  gap: 8px;
`

const CheckBoxWrapStyled = styled.div`
  padding-left: 80px;
`

const HiddenCellData = styled.div<{
  hideValue: boolean
}>`
  opacity: ${({ hideValue }) => (hideValue ? 0 : 1)};
  transition: 0.2s all;
`

const columns: Column[] = [
  {
    Header: 'Dataset Name − Short Name',
    accessor: 'name',
    Cell: ({ value, row, rows }: any) => {
      const { shortName, id } = row.original
      const { to, state } = useOpenDataset(id)

      if (!value) {
        return null
      }

      const prevRow = rows[getPrevRowIndex<TRowData>(rows, row.id)]

      return (
        <HiddenCellData hideValue={prevRow?.original.name === value}>
          <span>{value}</span> -{' '}
          <Link to={to} state={state} sx={{ fontWeight: 400 }}>
            {shortName}
          </Link>
        </HiddenCellData>
      )
    }
  },
  {
    Header: 'Year',
    accessor: 'year',
    Cell: ({ value, row, rows }: any) => {
      const prevRow = rows[getPrevRowIndex<TRowData>(rows, row.id)]
      return <HiddenCellData hideValue={prevRow?.original.file === row.original.file}>{value}</HiddenCellData>
    }
  },
  {
    Header: 'File',
    accessor: 'file',
    Cell: ({ value, row, rows }: any) => {
      const prevRow = rows[getPrevRowIndex<TRowData>(rows, row.id)]
      return <HiddenCellData hideValue={prevRow?.original.file === value}>{value}</HiddenCellData>
    }
  },
  {
    Header: () => (
      <HeaderWrapper>
        <span>Variable Code</span>
        <Hint
          iconWidth={20}
          withDefaultColor
          placement="top"
          title="Click on a variable code to see the possible values for that variable."
          maxWidth={208}
        />
      </HeaderWrapper>
    ),
    accessor: 'varCode',
    Cell: (props: CellProps<any>) => <VariableCodeCell {...props} />
  },
  {
    Header: 'Variable Description',
    accessor: 'varDescription',

    Cell: ({ value }) => {
      const {
        comparedKeywords: [keywordData]
      } = useKeywordsStateContext()
      return <TextHighlighter text={value} searchQuery={keywordData?.name || ''} />
    }
  },
  {
    Header: () => {
      const navigate = useNavigate()
      const { comparedVariables, clear } = useComparedVariablesContext()
      return (
        <ButtonWrapStyled>
          <Button
            disabled={!comparedVariables.length}
            onClick={clear}
            variant="secondary"
            sx={{
              padding: '4px 10px',
              fontSize: '13px',
              lineHeight: '22px',
              height: '30px',
              width: 'max-content'
            }}
          >
            Clear All
          </Button>
          <CompareButton disabled={!comparedVariables.length} onClick={() => navigate(ROUTES.COMPARE_VALUES)} text="values" />
        </ButtonWrapStyled>
      )
    },
    accessor: 'checkbox',
    Cell: (props: CellProps<any>) => (
      <CheckBoxWrapStyled>
        <VariableSelectCell {...props} />
      </CheckBoxWrapStyled>
    )
  }
]

type TProps = {
  tableData?: any
}

const Table: FC<TProps> = ({ tableData }) => {
  const data = useMemo(() => prepareDatasetsForTable(tableData ?? []), [tableData])

  return (
    <StripedTable
      data={data}
      columns={columns}
      stickyHeader
      stickyRawBody
      isInitiallyExpanded
      sx={{
        '& .MuiTableHead-root .MuiTableCell-root': {
          whiteSpace: 'nowrap'
        },
        '& .MuiTableBody-root': {
          '.MuiTableRow-root:hover td > div': {
            opacity: 1
          },
          '.MuiTableCell-root': {
            verticalAlign: 'top',
            '&:last-child': {
              width: '10%',
              textAlign: 'center'
            }
          }
        }
      }}
    />
  )
}

export default memo(Table)
