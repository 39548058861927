import { DialogTitle, DialogTitleProps } from '@mui/material'
import { FC } from 'react'
import { useTheme } from 'styled-components'

const ModalTitle: FC<DialogTitleProps> = ({ sx, ...rest }) => {
  const { colors } = useTheme()

  return (
    <DialogTitle
      {...rest}
      sx={{
        color: colors.black,
        padding: '24px 40px 24px 20px',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '24px',
        ...sx
      }}
    />
  )
}

export default ModalTitle
