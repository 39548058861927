import styled from 'styled-components'
import React, { useEffect } from 'react'
import Card from '../../../shared/Card'
import Header from './Header'
import Search from './Search'
import Pagination from './Pagination'
import UpdatesTable from './UpdatesTable'
import { ImportProvider, useImportContext } from '../state/useFileImport'
import { UpdatesProvider, useUpdatesContext } from '../state/useUpdates'
import Spinner from '../../../shared/Spinner'
import { PaginationProvider } from '../../../../hooks/usePagination'
import { SearchProvider } from '../state/useSearch'
import { usePaginationInit } from '../hooks/usePaginationInit'
import ImportModal from './ImportModal'
import { ImportModalProvider } from '../state/useImportModal'

const SpinnerStyled = styled(Spinner)`
  margin: 20px 0;
`

const CardStyled = styled(Card)`
  padding: 20px 20px 2px;
  margin-bottom: 20px;
`

const SearchStyled = styled(Search)`
  width: 300px;
  margin: 8px 0 16px !important;
`

const DataManagement = () => {
  const {
    fetchUpdates,
    isFetching
  } = useUpdatesContext()
  const { isUploading } = useImportContext()
  usePaginationInit()
  const isLoading = isFetching || isUploading

  useEffect(
    fetchUpdates,
    []
  )

  return (
    <div>
      <CardStyled>
        <Header />
        {
          isLoading
            ? <SpinnerStyled />
            : (
              <>
                <SearchStyled />
                <UpdatesTable />
              </>
            )
        }
      </CardStyled>
      {!isLoading && (
        <Pagination />
      )}
      <ImportModal />
    </div>
  )
}

export default () => (
  <PaginationProvider>
    <ImportModalProvider>
      <UpdatesProvider>
        <ImportProvider>
          <SearchProvider>
            <DataManagement />
          </SearchProvider>
        </ImportProvider>
      </UpdatesProvider>
    </ImportModalProvider>
  </PaginationProvider>
)
