import Highlighter from 'react-highlight-words'
import { FC } from 'react'
import styled from 'styled-components'

const HighlighterStyled = styled(Highlighter)`
  &&{
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.black};
      
      & .bold{
          font-weight: 700;
      }
  }
`

type TProps={
  searchQuery:string
  text:string
}
const TextHighlighter:FC<TProps> = ({ searchQuery, text }) => (
  <HighlighterStyled
    highlightTag="span"
    highlightClassName="bold"
    searchWords={[searchQuery]}
    autoEscape
    textToHighlight={text}
  />
)

export default TextHighlighter
