import { useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { useEditUserMutation } from '../queries/useEditUserMutation'
import { TUserForm } from '../definitions'
import { USERS_QUERY_KEY } from '../queries/useUsersQuery'
import { useUserContext } from '../state/useUser'
import { prepareDataForUpdate } from '../utils'

const useEditUser = (onSuccess: () => void, onError: (error: AxiosError) => void) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useEditUserMutation()
  const user = useUserContext()

  const editUser = async (formData: TUserForm) => {
    try {
      await mutateAsync({ formData: prepareDataForUpdate(formData), userId: user.id })
      onSuccess()
      await queryClient.invalidateQueries(USERS_QUERY_KEY)
    } catch (error) {
      onError(error as AxiosError)
    }
  }

  return {
    editUser,
    isLoading
  }
}

export default useEditUser
