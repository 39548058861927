import { FC } from 'react'
import styled from 'styled-components'
import Modal from '../../../../shared/modal/Modal'
import ModalContent from '../../../../shared/modal/ModalContent'
import { ReactComponent as SuccessIcon } from '../../../../../assets/SuccessIcon.svg'

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 428px;
`

const TextStyled = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
`

const DescriptionStyled = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
`

type Props={
  isOpen: boolean
  onClose: () => void
}

const SuccessModal:FC<Props> = ({ onClose, isOpen }) => (
  <Modal
    open={isOpen}
    closeModal={onClose}
    withCloseButton
    sx={{
      '& .MuiPaper-root': {
        maxWidth: '508px'
      }
    }}
  >
    <ModalContent sx={{ marginTop: '30px' }}>
      <ContentStyled>
        <SuccessIcon />
        <TextStyled>Thank you.</TextStyled>
        <DescriptionStyled>Your feedback is valuable to us.</DescriptionStyled>
      </ContentStyled>
    </ModalContent>

  </Modal>

)

export default SuccessModal
