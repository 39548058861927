import constate from 'constate'
import { useQueryClient } from 'react-query'
import { useFetchDatasets } from '../hooks/useFetchDatasets'
import { useDatasetsUpdate } from '../hooks/useDatasetsUpdate'
import { DATASETS_QUERY_KEY } from '../queries/useDatasetsQuery'
import { TDataset } from '../definitions'

const useDatasets = () => {
  const { isFetching } = useFetchDatasets()
  const { isUpdating } = useDatasetsUpdate()

  return {
    isLoading: isUpdating || isFetching,
    datasets: useQueryClient().getQueryData<TDataset[]>(DATASETS_QUERY_KEY)
  }
}

export const [DatasetsProvider, useDatasetsContext] = constate(useDatasets)
