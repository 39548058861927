import { useQuery } from 'react-query'
import { apiClient } from '../api/apiClient'
import { TParsingStatus } from '../components/features/dataManagement/definitions'

type TResponse = {
  fileName: string
  importingProgress: number
  status: TParsingStatus
}

const getParsingStatus = async (): Promise<TResponse> => await apiClient
  .get('/parse/status')
  .then((response) => response?.data)

const REFETCH_INTERVAL = 5000

export const PARSING_STATUS_QUERY_KEY = 'getParsingStatus'

export const useParsingStatusQuery = (enabled: boolean) => useQuery(
  PARSING_STATUS_QUERY_KEY,
  getParsingStatus,
  {
    enabled,
    cacheTime: 0,
    refetchInterval: () => (
      enabled ? REFETCH_INTERVAL : 0
    )
  }
)
