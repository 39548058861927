import { ReactElement, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Button from '../../../shared/Button'
import Progress from '../../../shared/Progress'
import Modal from '../../../shared/modal/Modal'
import ModalTitle from '../../../shared/modal/ModalTitle'
import ModalActions from '../../../shared/modal/ModalActions'
import ModalContent from '../../../shared/modal/ModalContent'
import { useUpdatesContext } from '../state/useUpdates'
import { useImportContext } from '../state/useFileImport'
import { useImportModalContext } from '../state/useImportModal'
import { ReactComponent as CheckIcon } from '../assets/Check.svg'
import { ReactComponent as CrossIcon } from '../assets/Cross.svg'
import { useClearStatusMutation } from '../queries/useClearStatusMutation'

const titleBaseStyles = css`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: max-content auto;
    grid-column-gap: 8px;
`

const BaseTitleStyled = styled.span`
    ${titleBaseStyles};
    color: ${({ theme }) => theme.colors.black};
`

const SuccessTitleStyled = styled.span`
    ${titleBaseStyles};
    color: ${({ theme }) => theme.colors.blue.primary}
`

const ErrorTitleStyled = styled.div`
    ${titleBaseStyles};
    color: ${({ theme }) => theme.colors.red.primary}
`

const ImportingDescriptionStyled = styled.span`
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 18px;
    line-height: 24px;
`

const ParsingDescriptionStyled = styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
`

const ErrorDescriptionStyled = styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;
    line-height: 20px;
`

type TModelContent = {
  title: ReactElement
  description?: ReactElement
}

const useModalContent = (): TModelContent => {
  const { isParsing, isError: isParsingError } = useUpdatesContext()
  const { isUploading, isError: isFileUploadError } = useImportContext()
  const { fileName } = useImportModalContext()

  if (isUploading) {
    return {
      title: <BaseTitleStyled>{`Uploading ${fileName}...`}</BaseTitleStyled>,
      description: <ImportingDescriptionStyled>{`Importing ${fileName}`}</ImportingDescriptionStyled>
    }
  }
  if (isParsing) {
    return {
      title: (
        <SuccessTitleStyled>
          <CheckIcon />
          Uploading completed
        </SuccessTitleStyled>),
      description: <ParsingDescriptionStyled>{`Importing ${fileName}...`}</ParsingDescriptionStyled>
    }
  }
  if (isParsingError || isFileUploadError) {
    return {
      title: (
        <ErrorTitleStyled>
          <CrossIcon />
          The import process has failed!
        </ErrorTitleStyled>
      ),
      description: <ErrorDescriptionStyled>Please review your file and attempt the import again</ErrorDescriptionStyled>
    }
  }
  return {
    title: (
      <SuccessTitleStyled>
        <CheckIcon />
        {`Importing ${fileName} successfully completed!`}
      </SuccessTitleStyled>
    )
  }
}

const useCancelButton = () => {
  const { closeModal } = useImportModalContext()
  const { cancelUploading, isUploading } = useImportContext()
  const { isParsing } = useUpdatesContext()
  const { mutateAsync } = useClearStatusMutation()

  const handleCancel = () => {
    closeModal()
    cancelUploading()
  }

  const handleClose = async () => {
    closeModal()
    await mutateAsync()
  }

  return {
    text: isParsing || isUploading ? 'Cancel' : 'Close',
    disabled: isParsing,
    onClick: isUploading ? handleCancel : handleClose
  }
}

const useProgressState = () => {
  const { isUploading } = useImportContext()
  const { isParsing } = useUpdatesContext()
  const { progress } = useImportModalContext()

  return {
    isVisible: isUploading || isParsing,
    value: progress
  }
}

const useOpenModal = () => {
  const { parsingStatus } = useUpdatesContext()
  const { isUploading } = useImportContext()
  const { open, openModal } = useImportModalContext()

  useEffect(() => {
    if ((isUploading || (parsingStatus && parsingStatus !== 'FILE_UPLOADING')) && !open) {
      openModal()
    }
  }, [isUploading, parsingStatus])
}

const ImportModal = () => {
  const { open } = useImportModalContext()
  const { isVisible, value } = useProgressState()
  const { title, description } = useModalContent()
  const { text, disabled, onClick } = useCancelButton()
  useOpenModal()

  return (
    <Modal open={open}>
      <ModalTitle sx={{
        margin: '24px 20px 0',
        padding: 0,
        width: 'auto'
      }}
      >
        { title }
      </ModalTitle>
      {(!!description || isVisible) && (
        <ModalContent
          sx={{
            display: 'grid',
            gridRowGap: '24px',
            margin: '8px 20px 0',
            padding: 0
          }}
        >
          { description }
          {isVisible && <Progress value={value} />}
        </ModalContent>
      )}
      <ModalActions
        sx={{
          marginTop: '24px'
        }}
      >
        <Button
          variant="secondary"
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ImportModal
