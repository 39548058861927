import styled from 'styled-components'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDatasetsQuery } from '../../datasets/queries/useDatasetsQuery'
import { ReactComponent as ToolIcon } from '../../../../assets/Tool.svg'
import useUploadingStatus from '../../../../hooks/useUploadingStatus'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.blue.light};
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContainerStyled = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.shadow}
}

;

`

const ContentStyled = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-top: 24px;
`

const DescriptionStyled = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey.dark};
  margin-top: 12px;
`

const ContactStyled = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey.dark};
`

const LinkStyled = styled.a`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue.primary};
`

const Maintenance = () => {
  const { isSuccess } = useDatasetsQuery()
  const isUploadingGeneralUserStatus = useUploadingStatus()
  const navigate = useNavigate()
  useEffect(() => {
    if (isSuccess && !isUploadingGeneralUserStatus) {
      navigate('/datasets')
    }
  }, [isSuccess])

  return (
    <Wrapper>
      <ContainerStyled>
        <ToolIcon />
        <ContentStyled>
          {/* eslint-disable-next-line no-irregular-whitespace */}
          Health Data Select is not available at this time due to required maintenance.
        </ContentStyled>
        <DescriptionStyled>We apologize for the inconvenience.</DescriptionStyled>

        <ContactStyled>
          To contact us, send an email to{' '}
          <LinkStyled target="_blank" href="mailto://HDS.help@sternconsulting.com">
            HDS.help@sternconsulting.com
          </LinkStyled>
        </ContactStyled>
      </ContainerStyled>
    </Wrapper>
  )
}

export default Maintenance
