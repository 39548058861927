import { useEffect } from 'react'
import { useComparedVariablesContext } from '../state/useComparedVariablesState'
import {
  getDatasetNamesFromVariables,
  getVariablesFromVersions,
  prepareDataForSubmit,
  prepareDatasetVariables,
  prepareKeywordData
} from '../utils'
import { useTableRowsContext } from '../state/useTableRows'
import { useKeywordDetailsQuery } from '../../../../queries/useKeywordDetailsQuery'
import { useCompareVariablesQuery } from '../../../../queries/useCompareVariablesQuery'
import { TDatasetsVariablesData, TDatasetVariablesData } from '../../../../definitions/variable'
import { useIsAcrossDatasetsComparison } from './useIsAcrossDatasetsComparison'
import { useDetailsStateContext } from '../state/useDetailsState'
import { usePaginationContext } from '../../../../hooks/usePagination'

export const useFetchValues = () => {
  const { comparedVariables, comparedVariable } = useComparedVariablesContext()
  const isAcrossDatasetsComparison = useIsAcrossDatasetsComparison()
  const { page, startCount, rowsPerPage } = usePaginationContext()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const preparedCompareVariables = comparedVariables.length ? comparedVariables : comparedVariable ? [comparedVariable] : []
  const keywords = prepareDataForSubmit(preparedCompareVariables)
  const { setTableRows, tableRows } = useTableRowsContext()
  const { setDatasets, setVariables } = useDetailsStateContext()
  const { data: keywordData, isFetchedAfterMount: isKeywordDataFetched } = useKeywordDetailsQuery(
    keywords[0],
    true,
    isAcrossDatasetsComparison,
    startCount,
    rowsPerPage,
    page
  )
  const { data: variables, isFetchedAfterMount: isVariablesFetched } = useCompareVariablesQuery(keywords, true, !isAcrossDatasetsComparison)

  const isFetched = isKeywordDataFetched || isVariablesFetched

  const keywordNames = keywords.map((keyword) => keyword.name.toLowerCase())

  useEffect(() => {
    if (!tableRows && isFetched) {
      if (isAcrossDatasetsComparison && keywordData) {
        setTableRows(prepareKeywordData(keywordData as TDatasetsVariablesData[], keywordNames))
        setDatasets(getDatasetNamesFromVariables(keywordData as TDatasetsVariablesData[]))
        setVariables(getVariablesFromVersions(keywordData?.flatMap(({ versions }) => versions) ?? []))
        return
      }
      if (variables) {
        setTableRows(prepareDatasetVariables(variables as TDatasetVariablesData[], preparedCompareVariables[0].dataset, keywordNames))
        setDatasets([preparedCompareVariables[0].dataset.name])
        setVariables(getVariablesFromVersions(variables?.flatMap(({ versions }) => versions) ?? []))
      }
    }
  }, [isFetched, preparedCompareVariables, keywordData, variables])

  return { isFetched: isFetched && !!tableRows }
}
