import { useQuery } from 'react-query'

import { apiClient } from '../../../../api/apiClient'
import { TUsersData } from '../definitions'
import { usePaginationContext } from '../../../../hooks/usePagination'

const getUsers = async (page: number, size: number): Promise<TUsersData> => await apiClient
  .get('/users', { params: { page: page - 1, size } })
  .then((response) => response?.data)

export const USERS_QUERY_KEY = 'getUsers'

export const useUsersQuery = (
  enabled?: boolean,
  onSuccess?: (data: TUsersData) => void
) => {
  const { page, rowsPerPage } = usePaginationContext()
  return useQuery(
    [USERS_QUERY_KEY, page],
    () => getUsers(page, rowsPerPage),
    {
      enabled,
      onSuccess,
      cacheTime: 0
    }
  )
}
