import { FC } from 'react'
import IconButton from '../../IconButton'
import { ReactComponent as ChevronDown } from '../assets/ChevronDown.svg'
import { ReactComponent as ChevronRight } from '../assets/ChevronRight.svg'

type TProps = {
  isRowExpanded: boolean,
  onClick: () => void
}

const ExpandButton: FC<TProps> = ({ isRowExpanded, onClick }) => (
  <IconButton onClick={onClick}>
    {
      isRowExpanded
        ? <ChevronDown />
        : <ChevronRight />
    }
  </IconButton>
)

export default ExpandButton
