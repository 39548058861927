import styled from 'styled-components'
import { FC } from 'react'
import FilteredDatasets from './FilteredDatasets'
import Spinner from '../../../../shared/Spinner'
import NotFoundMessage from './NotFoundMessage'
import Card from '../../../../shared/Card'
import SearchField from './SearchField'
import { useFiltersStateContext } from '../../state/useFiltersState'
import { useDatasetsContext } from '../../state/useDatasets'
import { useKeywordsStateContext } from '../../state/useKeywordsState'
import KeywordsSearchResults from './KeywordsSearchResults'

const SpinnerStyled = styled(Spinner)`
  padding-bottom: 20px;
`

const NotFoundMessageStyled = styled(NotFoundMessage)`
  padding: 0 20px 20px 20px;
`

const SearchCardStyled = styled(Card)`
  padding-top: 20px;
  overflow-x: hidden;
`

const SearchFieldStyled = styled(SearchField)`
  padding: 0 20px 20px;
`

const Search: FC = () => {
  const { datasets, isLoading } = useDatasetsContext()
  const isFilterActive = !!useFiltersStateContext().getSelectedFiltersCount()
  const isSearchActive = !!useKeywordsStateContext().submittedKeywords

  const content = (() => {
    if (!isFilterActive && !isSearchActive) {
      return null
    }
    if (isLoading) {
      return <SpinnerStyled />
    }
    if (datasets?.length === 0) {
      return <NotFoundMessageStyled />
    }
    if (isSearchActive) {
      return <KeywordsSearchResults />
    }
    return <FilteredDatasets />
  })()

  return (
    <SearchCardStyled>
      <SearchFieldStyled />
      {content}
    </SearchCardStyled>
  )
}

export default Search
