import { useMutation } from 'react-query'
import { apiClient } from '../../../api/apiClient'

async function sendFeedback(payload:FormData):Promise<void> {
  try {
    await apiClient.post('/feedback', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (e:any) {
    throw new Error(e?.response?.data?.details || e || 'Something went wrong')
  }
}

export function useSendFeedbackMutation() {
  return useMutation<void, Error, FormData>(
    (payload) => sendFeedback(payload)
  )
}
