import { useMutation } from 'react-query'
import { TForm } from '../definitions'
import { apiClient } from '../../../../api/apiClient'
import { TAuthData, TTokenData, TUserData } from '../../../../definitions/auth'

export const REQUEST_TOKEN_API_URL = '/auth/request-token'

const getUser = async (accessToken: string): Promise<TUserData> =>
  await apiClient
    .get('/users/current', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then((response) => response?.data)

const getTokens = async (data: TForm): Promise<TTokenData> =>
  await apiClient.post(REQUEST_TOKEN_API_URL, data).then((response) => response?.data)

const login = async (data: TForm): Promise<TAuthData> => {
  const tokens = await getTokens(data)
  const user = await getUser(tokens.access_token)

  return { ...tokens, ...user }
}

const LOGIN_MUTATION_KEY = 'login'

export const useLoginMutation = () => useMutation(LOGIN_MUTATION_KEY, login)
