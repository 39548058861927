import React, { FC } from 'react'
import styled from 'styled-components'
import { TSection } from '../definitions'
import Card from '../../../shared/Card'
import { ReactComponent as DatabaseIcon } from '../assets/Database.svg'
import { ReactComponent as UsersIcon } from '../assets/Users.svg'
import { ReactComponent as FeedbackLine } from '../../../../assets/FeedbackLine.svg'
import MenuButton from '../../../shared/MenuButton'
import { useAuthContext } from '../../../../state/useAuth'

const CardStyled = styled(Card)`
  padding: 16px 12px;
  display: grid;
  grid-row-gap: 4px;
`

type TProps = {
  onChange: (value: TSection) => void,
  activeSection: TSection
  className?: string,
}

type TItem = {
  value: TSection,
  label: string,
  icon: React.ReactElement
}

const feedback = {
  value: 'feedback',
  label: 'Help & Feedback',
  icon: <FeedbackLine />
}

function useItem() {
  const { authData } = useAuthContext()
  const isAdmin = authData?.userRole === 'admin'
  const items: TItem[] = [
    {
      value: 'dataManagement',
      label: 'Data Management',
      icon: <DatabaseIcon />
    },
    {
      value: 'userManagement',
      label: 'User Management',
      icon: <UsersIcon />
    }
  ]

  return isAdmin ? [...items, feedback] : items
}

const NavMenu: FC<TProps> = ({ onChange, activeSection, className }) => (
  <CardStyled className={className}>
    {useItem().map(({ value, label, icon }) => (
      <MenuButton
        isActive={value === activeSection}
        startIcon={icon}
        fullWidth
        onClick={() => onChange(value as TSection)}
        key={value}
      >
        {label}
      </MenuButton>
    ))}
  </CardStyled>
)

export default NavMenu
