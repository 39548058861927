import styled from 'styled-components'
import { FC } from 'react'
import Card from '../../../../shared/Card'
import Button from '../../../../shared/Button'
import { useFiltersQuery } from '../../queries/useFiltersQuery'
import Spinner from '../../../../shared/Spinner'
import FilterButton from './FilterButton'
import { TFilter } from '../../definitions'
import { divideFiltersByGroups } from '../../utils'
import { useFiltersStateContext } from '../../state/useFiltersState'

const CardStyled = styled(Card)`
  height: min-content;
  padding: 16px 12px;
  color: ${({ theme }) => theme.colors.black};
`

const TitleWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`

const TitleStyled = styled.h1`
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
`

const SubTitleStyled = styled.h2`
  color: ${({ theme }) => theme.colors.grey.dark};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 6px 0 10px;
`

const GroupsWrapperStyled = styled.div`
  display: grid;
  grid-row-gap: 16px;
`

const GroupWrapperStyled = styled.div`
  display: grid;
  grid-row-gap: 4px;
`

type TProps = {
  className?: string
}

const useGroupedFilters = (): TFilter[][] => {
  const filters = useFiltersQuery(false).data
  return divideFiltersByGroups(filters ?? [])
}

const Filters: FC<TProps> = ({ className }) => {
  const { isLoading } = useFiltersQuery()
  const groupedFilters = useGroupedFilters()
  const { clearAll } = useFiltersStateContext()

  const content = (
    <>
      <TitleWrapperStyled>
        <TitleStyled>Dataset Filters</TitleStyled>
        <Button
          variant="secondary"
          sx={{
            padding: '0 8px',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '20px',
            height: '20px'
          }}
          onClick={clearAll}
        >
          Reset all filters
        </Button>
      </TitleWrapperStyled>
      <SubTitleStyled>Click on filter to see filter parameters</SubTitleStyled>
      <GroupsWrapperStyled>
        {groupedFilters.map((group, index) => (
          <GroupWrapperStyled key={index}>
            {group.map((filter) => (
              <FilterButton
                filterData={filter}
                key={filter.name}
              />
            ))}
          </GroupWrapperStyled>
        ))}
      </GroupsWrapperStyled>
    </>
  )

  return (
    <CardStyled className={className}>
      {
        isLoading
          ? <Spinner />
          : content
      }
    </CardStyled>
  )
}

export default Filters
