import styled from 'styled-components'
import { ReactComponent as CloseLineIcon } from '../../../../assets/CloseLine.svg'

const IconWrapper = styled.div`
    color: #768A9A;
    display: flex;
    justify-content: center;
    align-items: center;
`

const NotificationIcon = () => (
  <IconWrapper>
    <CloseLineIcon fill="#768A9A" width={24} height={24} />
  </IconWrapper>
)
export default NotificationIcon
