import { useQuery } from 'react-query'
import { apiClient } from '../../../../api/apiClient'
import { TDataset } from '../../../../definitions/dataset'

const getDataset = async (id: string): Promise<TDataset> => await apiClient
  .get(`/datasets/${id}`)
  .then((response) => response?.data)

export const DATASET_QUERY_KEY = 'datasetDetails'

export const useDatasetQuery = (id: string) => useQuery(
  [DATASET_QUERY_KEY],
  () => getDataset(id),
  {
    cacheTime: 0
  }
)
