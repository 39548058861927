import { useMemo } from 'react'
import { useDatasetsQuery } from '../queries/useDatasetsQuery'

export const useTableData = (datasetsIds: string[]) => {
  const { data, isFetching } = useDatasetsQuery(datasetsIds)

  const rows = useMemo(
    () => data,
    [data]
  )

  return {
    isFetching,
    data: rows
  }
}
