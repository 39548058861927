import styled from 'styled-components'
import { Navigate } from 'react-router-dom'
import Filters from './filters/Filters'
import AvailableDatasets from './AvailableDatasets'
import { useFiltersStateContext } from '../state/useFiltersState'
import Search from './search/Search'
import useSelectReset from '../hooks/useSelectReset'
import { DatasetsProvider } from '../state/useDatasets'
import { useKeywordsStateContext } from '../state/useKeywordsState'
import { ROUTES } from '../../../../constants/routes'
import useUploadingStatus from '../../../../hooks/useUploadingStatus'

const ContainerStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  grid-template-columns: 244px 1fr;
`

const ContentWrapperStyled = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-auto-rows: max-content;
`

const Datasets = () => {
  useSelectReset()
  const isFilterActive = !!useFiltersStateContext().getSelectedFiltersCount()
  const isSearchActive = !!useKeywordsStateContext().submittedKeywords
  const isUploadingGeneralUserStatus = useUploadingStatus()

  if (isUploadingGeneralUserStatus) return <Navigate to={ROUTES.MAINTENANCE} />

  return (
    <DatasetsProvider>
      <ContainerStyled>
        <Filters />
        <ContentWrapperStyled>
          <Search />
          {!isFilterActive && !isSearchActive && <AvailableDatasets />}
        </ContentWrapperStyled>
      </ContainerStyled>
    </DatasetsProvider>
  )
}

export default Datasets
