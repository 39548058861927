import { usePaginationContext } from '../../../../hooks/usePagination'
import BasePagination from '../../../shared/pagination/Pagination'

const Pagination = () => {
  const {
    page,
    pagesCount,
    setPage
  } = usePaginationContext()

  return (
    <BasePagination
      page={page}
      count={pagesCount}
      onChange={(e, page) => setPage(page)}
    />
  )
}

export default Pagination
