import { Column } from 'react-table'
import Table from '../../../shared/table/components/Table'
import UserActions from './UserActions'
import { UserProvider } from '../state/useUser'
import { useTableData } from '../hooks/useTableData'
import { TUser } from '../definitions'
import { capitalizeFirstLetter } from '../../../../utils/string'

const columns: Column[] = [
  {
    Header: 'First Name',
    accessor: 'firstName'
  },
  {
    Header: 'Last Name',
    accessor: 'lastName'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Organization',
    accessor: 'organization',
    Cell: ({ value }) => value || '-'
  },
  {
    Header: 'Role',
    accessor: 'userRole',
    Cell: ({ value }) => <>{capitalizeFirstLetter(value)}</>
  },
  {
    accessor: 'actions',
    Cell: ({ row }) => (
      <UserProvider user={row.original as TUser}>
        <UserActions />
      </UserProvider>
    )
  }
]

const UsersTable = () => {
  const data = useTableData()

  return (
    <Table data={data ?? []} columns={columns} />
  )
}

export default UsersTable
