import { DialogProps } from '@mui/material'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Modal from '../../../shared/modal/Modal'
import ModalTitle from '../../../shared/modal/ModalTitle'
import ModalContent from '../../../shared/modal/ModalContent'
import ModalActions from '../../../shared/modal/ModalActions'
import Button from '../../../shared/Button'
import UserForm from './UserForm'
import { TUserForm } from '../definitions'
import { generatePassword, setEmailValidationError } from '../utils'
import { useCreateUser } from '../hooks/useCreateUser'

type TProps = {
  closeModal: () => void
} & DialogProps

const CreateUserModal: FC<TProps> = ({ closeModal, open, ...rest }) => {
  const methods = useForm<TUserForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      password: '',
      userRole: 'user'
    }
  })
  const { createUser, isLoading } = useCreateUser(
    closeModal,
    (error) => setEmailValidationError(methods, error)
  )

  useEffect(() => {
    if (open) {
      methods.reset()
      methods.setValue('password', generatePassword())
    }
  }, [open])

  return (
    <Modal open={open} closeModal={closeModal} withCloseButton {...rest}>
      <ModalTitle>
        Create user
      </ModalTitle>
      <ModalContent>
        <FormProvider {...methods}>
          <UserForm />
        </FormProvider>
      </ModalContent>
      <ModalActions>
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={methods.handleSubmit(createUser)}
          disabled={isLoading}
          loading={isLoading}
        >
          Create
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default CreateUserModal
