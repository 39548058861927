import { useState } from 'react'
import { useFilterContext } from '../state/useFilter'
import { useFiltersStateContext } from '../state/useFiltersState'
import { TLogic } from '../../../shared/logicToggle/LogicToggle'

export const useFilterState = () => {
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const [logic, setLogic] = useState<TLogic>('OR')
  const { filterData: { shortName } } = useFilterContext()
  const { getFilterValues } = useFiltersStateContext()

  const selectValue = (value: string) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value))
    } else {
      setSelectedValues([...selectedValues, value])
    }
  }

  return {
    logic,
    setLogic,
    isValueSelected: (value: string) => selectedValues.includes(value),
    selectedValues,
    selectValue,
    initState: () => setSelectedValues((getFilterValues(shortName) as any)?.filters ?? []),
    clearState: () => setSelectedValues([]),
    setSelectedValues: (values: string[]) => setSelectedValues(values)
  }
}
