import React, { ChangeEvent, FC, useCallback } from 'react'
import { useFormContext, UseFormSetError } from 'react-hook-form'
import FileUpload from './FileUpload'
import ImageFile from './ImageFile'

function useImageHandleChange(
  name: string,
  setError: UseFormSetError<any>
) {
  return useCallback((
    { target }: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    if (target && target.files) {
      const file = target.files[0]
      const fileSize = file.size
      const fileType = file.type.split('/')[1]
      const fileSizeToMB = fileSize / 1024 / 1024
      if (fileSizeToMB > 5) {
        return
      }
      if (!imageFormats.includes(fileType)) {
        return
      }
      onChange(file)
      target.value = ''
    }
  }, [name, setError])
}

const imageFormats = ['jpg', 'jpeg', 'png']

type TProps = {
  label: string
}

const ImageFileUpload: FC<TProps> = ({
  label
}) => {
  const {
    control,
    setValue,
    clearErrors,
    setError, watch
  } = useFormContext()
  const imageWatch = watch('file')
  const handleImageUploadChange = useImageHandleChange('file', setError)

  const removeImage = () => {
    setValue('file', undefined)
    clearErrors('file')
  }
  if (imageWatch) {
    return (
      <ImageFile removeImage={removeImage} label={imageWatch.name} />
    )
  }

  return (
    <FileUpload
      label={label}
      title="Add Image"
      accept=" .jpg, .jpeg, .png"
      name="file"
      control={control}
      handleChange={handleImageUploadChange}
      clearError={() => clearErrors('file')}
    />
  )
}

export default ImageFileUpload
