import { generate } from 'generate-password-browser'
import { UseFormReturn } from 'react-hook-form'
import { AxiosError } from 'axios'
import { TUserForm } from './definitions'
import { EMAIL_EXISTS_MESSAGE } from './constants'

const generateNumber = (min: number, max: number) => Math.floor(min + Math.random() * (max + 1 - min))

export const generatePassword = () => generate({
  length: generateNumber(8, 16),
  numbers: true,
  lowercase: true,
  uppercase: true,
  symbols: true,
  strict: true
})

export const setEmailValidationError = ({ setError }: UseFormReturn<TUserForm>, error: AxiosError) => {
  if (error.response?.status === 409) {
    setError(
      'email',
      { message: EMAIL_EXISTS_MESSAGE }
    )
  }
}

export const prepareDataForUpdate = (formData: TUserForm) => ({
  ...formData,
  password: formData.password || null
})
