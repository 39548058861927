import { FC } from 'react'
import styled from 'styled-components'
import Tooltip, { TTooltipProps } from '../Tooltip'
import { ReactComponent as InfoIcon } from './assets/Info.svg'

const InfoIconStyled = styled(InfoIcon)<{ $withDefaultColor?: boolean, $width?: number }>`
  fill: ${({ theme: { colors }, $withDefaultColor }) => (
    $withDefaultColor ? colors.grey.dark : undefined
  )};
  width: ${({ $width }) => $width || 24}px;
`

type TProps = {
  iconWidth?: number,
  withDefaultColor?: boolean
} & Omit<TTooltipProps, 'children'>

const Hint: FC<TProps> = ({ iconWidth, withDefaultColor, ...rest }) => (
  <Tooltip {...rest}>
    <InfoIconStyled $withDefaultColor={withDefaultColor} $width={iconWidth} />
  </Tooltip>
)

export default Hint
