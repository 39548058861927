import { FC, useCallback } from 'react'
import styled, { createGlobalStyle, useTheme } from 'styled-components'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useSendFeedbackMutation } from '../../queries'
import Button from '../../../../shared/Button'
import { FeedbackType } from '../../types'
import FormTextField from '../../../../shared/formControls/FormTextField'
import ImageFileUpload from '../../../../shared/FileUpload/ImageFileUpload'
import { ReactComponent as WarningFillIcon } from '../../../../../assets/WarningFillIcon.svg'

import Modal from '../../../../shared/modal/Modal'
import ModalContent from '../../../../shared/modal/ModalContent'
import CustomTextArea from '../../../../shared/formControls/CustomTextArea'
import { useNavContext } from '../../../../../state/useNavState'

const ModalGlobalStyles = createGlobalStyle`
  .muiRoot {
    .MuiDialog-container.MuiDialog-container {
      align-items: flex-start;
    }

  }
`

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const HeaderStyled = styled.div`
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

const TitleStyled = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`

const DescriptionStyled = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey.dark};
`

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`

const InputWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
`

const WordCountStyled = styled.div`
  align-self: flex-end;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: ${({ theme }) => theme.colors.grey.dark};
  margin-top: 4px;
`

const ButtonWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & > button {
    width: 135px !important;
  }
`

const ErrorStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  & > span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: ${({ theme }) => theme.colors.red.primary};
  }
`

const SECTION_NAMES = {
  dataManagement: 'Data Management',
  userManagement: 'User Management',
  feedback: 'Feedback'
}

function useFormInit() {
  return useForm<FeedbackType>({
    defaultValues: {
      title: '',
      description: '',
      file: undefined
    }
  })
}

function useOnSubmit(callback: () => void, formReturn: UseFormReturn<any>) {
  const { mutateAsync, isError, error } = useSendFeedbackMutation()
  const queryClient = useQueryClient()
  const { pathname, search } = useLocation()
  const { activeSection } = useNavContext()
  const onSubmit = useCallback(
    (data: FeedbackType) => {
      const { file, description, title } = data
      const formData = new FormData()

      const isAdminPanel = pathname === '/admin-panel'

      formData.append('file', file)
      formData.append('description', description ?? '')
      formData.append('title', title ?? '')
      formData.append('page', `${pathname}${search} ${isAdminPanel ? `(${SECTION_NAMES[activeSection]})` : ''}` ?? '')

      mutateAsync(formData).then(() => {
        queryClient.invalidateQueries(['Feedback']).then(() => {
          formReturn.reset()
          callback()
        })
      })
    },
    [pathname, activeSection]
  )

  return {
    onSubmit,
    isError,
    error
  }
}

type Props = {
  isOpen: boolean
  onClose: () => void
  mutateCallback: () => void
}

const FeedbackModal: FC<Props> = ({ isOpen, mutateCallback, onClose }) => {
  const formReturn = useFormInit()
  const handleClose = () => {
    formReturn.reset()
    onClose()
  }
  const { colors } = useTheme()
  const { onSubmit, isError, error } = useOnSubmit(mutateCallback, formReturn)

  const handleChange = (value: string, limit: number) => {
    if (value.length > limit) {
      return value.substring(0, limit)
    }
    return value
  }

  return (
    <>
      <ModalGlobalStyles />
      <Modal rootClass="muiRoot" maxHeight="auto" width={600} open={isOpen} closeModal={handleClose} withCloseButton>
        <ModalContent>
          <FormProvider {...formReturn}>
            <HeaderStyled>
              <TitleStyled>Suggest an improvement for Health Data Select</TitleStyled>
              <DescriptionStyled>Please provide your suggestions and feedback here</DescriptionStyled>
            </HeaderStyled>
            <FormStyled onSubmit={formReturn.handleSubmit(onSubmit)}>
              <ContentStyled>
                <InputWrapStyled>
                  <FormTextField
                    placeholder="Your suggestion"
                    fullWidth
                    sx={{
                      input: {
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: colors.black,
                        '&::placeholder': {
                          color: colors.grey.dark,
                          opacity: 1,
                          fontWeight: 400
                        }
                      }
                    }}
                    label="Title"
                    name="title"
                    control={formReturn.control}
                    handleChange={(value) => handleChange(value, 150)}
                  />
                  <WordCountStyled>{`${formReturn.watch('title')?.length ?? 0}/150`}</WordCountStyled>
                </InputWrapStyled>

                <InputWrapStyled>
                  <CustomTextArea
                    placeholder="Description of your suggestion (optional)"
                    minRows={9}
                    name="description"
                    label="Description"
                    control={formReturn.control}
                    handleChange={(value) => handleChange(value, 1000)}
                  />
                  <WordCountStyled>{`${formReturn.watch('description')?.length ?? 0}/1000`}</WordCountStyled>
                </InputWrapStyled>

                <ImageFileUpload label="Attach image -JPG, PNG, 5mb max (optional)" />
              </ContentStyled>
              <ButtonWrapStyled>
                <Button disabled={!formReturn.watch('title')} type="submit">
                  Send feedback
                </Button>

                {isError && (
                  <ErrorStyled>
                    <WarningFillIcon />
                    <span>{error?.message}</span>
                  </ErrorStyled>
                )}
              </ButtonWrapStyled>
            </FormStyled>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FeedbackModal
