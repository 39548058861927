import styled, { css } from 'styled-components'
import { FC } from 'react'

const TitleStyled = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 22px;
  display: block;
  margin-bottom: 16px;
`

const textStyles = css`
  color: ${({ theme }) => theme.colors.grey.dark};
  font-size: 14px;
  line-height: 18px;
`

const ListTitleStyled = styled.span`
  ${textStyles}
`

const ListItemStyled = styled.li`
  ${textStyles};

  &::marker {
    content: '';
  }

  &::before {
    content: '•';
    margin: 0 8px;
  }
`

const NotFoundMessage: FC<{ className?: string }> = ({ className }) => (
  <div className={className}>
    <TitleStyled>No datasets were found that match your criteria</TitleStyled>
    <ListTitleStyled>Suggestions:</ListTitleStyled>
    <ul>
      <ListItemStyled>Try changing filters</ListItemStyled>
      <ListItemStyled>Make sure that all words are spelled correctly</ListItemStyled>
      <ListItemStyled>Try different search terms</ListItemStyled>
      <ListItemStyled>Try more general search terms</ListItemStyled>
    </ul>
  </div>
)

export default NotFoundMessage
