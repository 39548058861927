import { useQuery } from 'react-query'
import { apiClient } from '../../../api/apiClient'
import { Feedback } from './types'

async function feedback() {
  try {
    const response = await apiClient.get('/feedback')
    return response.data
  } catch (e:any) {
    throw new Error(e)
  }
}

export function useFeedbackQuery() {
  return useQuery<Feedback[], Error>(['Feedback'], () => feedback())
}

async function downloadFeedback(fileId:number) {
  try {
    const response = await apiClient.get(
      `/feedback/download/${fileId}`,
      {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream'
        }
      }
    )

    const fileName = response.headers['content-disposition'].split('filename=')[1].split('"')[1]
    const url = window.URL.createObjectURL(response.data as any)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
  } catch (e:any) {
    throw new Error(e?.response?.data?.details || e || 'Something went wrong')
  }
}

export function useFeedbackDownloadQuery(fileId:number) {
  return useQuery<void, Error>(
    ['Feedback', fileId],
    () => downloadFeedback(fileId),
    { enabled: false }
  )
}
