import { useState } from 'react'

export const useModal = (): [boolean, () => void, () => void] => {
  const [open, setOpen] = useState(false)

  return [
    open,
    () => setOpen(false),
    () => setOpen(true)
  ]
}
