import { FC } from 'react'
import { HeaderProps } from 'react-table'
import styled from 'styled-components'
import { TRowData } from '../../../definitions'
import { useKeywordsStateContext } from '../../../state/useKeywordsState'
import { getVariablesIdsFromRows } from '../../../utils'
import { ROUTES } from '../../../../../../constants/routes'
import Tooltip from '../../../../../shared/Tooltip'
import Link from '../../../../../shared/Link'

const HeaderTextStyled = styled.div`
    text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
    color: ${({ theme }) => theme.colors.blue.primary};
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    padding: 0;
    background: transparent;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 92px;
    white-space: nowrap;
`

const LinkWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
    max-width: inherit;
   
`

const KeywordHeaderCell: FC<HeaderProps<TRowData>> = ({ data, column }) => {
  const { setComparedKeywords } = useKeywordsStateContext()
  const keyword = column.id

  const handleClick = () => {
    setComparedKeywords([{
      name: keyword,
      variablesId: getVariablesIdsFromRows(data, keyword)
    }])
  }
  const isVersionedKeyword = data.map(({ keywords }:any) => {
    const isVersion = keywords?.find(({ name }:any) => name === keyword)?.versions.length
    return Boolean(isVersion)
  }).some(Boolean)

  return (
    <Tooltip id={keyword} title={keyword} placement="top">
      {
        !isVersionedKeyword ? (
          <HeaderTextStyled>
            {keyword}
          </HeaderTextStyled>
        ) : (
          <LinkWrapper>
            <Link to={ROUTES.KEYWORD_DETAILS} onClick={handleClick}>{keyword}</Link>
          </LinkWrapper>
        )
      }
    </Tooltip>
  )
}

export default KeywordHeaderCell
