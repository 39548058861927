import constate from 'constate'
import { useState } from 'react'

const useDetailsState = () => {
  const [variables, setVariables] = useState<string[]>([])
  const [datasets, setDatasets] = useState<string[]>([])

  return {
    variables,
    datasets,
    setVariables: (variables: string[]) => setVariables(variables),
    setDatasets: (datasets: string[]) => setDatasets(datasets)
  }
}

export const [DetailsStateProvider, useDetailsStateContext] = constate(useDetailsState)
