import { useMutation, useQueryClient } from 'react-query'
import { apiClient } from '../../../../api/apiClient'
import { PARSING_STATUS_QUERY_KEY } from '../../../../queries/useParsingStatusQuery'

const clearStatus = async (): Promise<any> => await apiClient.get(
  '/parse/clear-status'
)

export const CLEAR_STATUS_MUTATION_KEY = 'clearStatus'

export const useClearStatusMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    CLEAR_STATUS_MUTATION_KEY,
    clearStatus,
    {
      onSuccess: () => {
        queryClient.removeQueries(PARSING_STATUS_QUERY_KEY)
      }
    }
  )
}
