import { useState } from 'react'
import Button from '../../../shared/Button'
import FeedbackModal from './modals/FeedbackModal'
import SuccessModal from './modals/SuccessModal'

const initialModalState = {
  feedbackModal: false,
  successModal: false
}

const Feedback = () => {
  const [modalState, setModalState] = useState(initialModalState)
  const onClose = () => {
    setModalState(
      (prevState) => ({ ...prevState, feedbackModal: false })
    )
  }
  return (
    <>
      <Button
        onClick={() => setModalState(
          (prevState) => ({ ...prevState, feedbackModal: true })
        )}
      >
        Feedback
      </Button>
      <FeedbackModal
        isOpen={modalState.feedbackModal}
        onClose={onClose}
        mutateCallback={() => setModalState({ successModal: true, feedbackModal: false })}
      />
      <SuccessModal isOpen={modalState.successModal} onClose={() => setModalState(initialModalState)} />
    </>
  )
}

export default Feedback
