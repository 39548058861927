import { FC } from 'react'
import { TableBody as MuiTableBody, TableCell, TableRow } from '@mui/material'
import { TableInstance } from 'react-table'
import styled, { useTheme } from 'styled-components'
import ExpandButton from './ExpandButton'
import { getSubRowPudding } from '../utils'

const CellWrapperStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 8px;
  grid-template-columns: max-content;
`

type TProps = {
  tableInstance: TableInstance
}

const TableBody: FC<TProps> = ({
  tableInstance: {
    getTableBodyProps,
    prepareRow,
    rows
  }
}) => {
  const { colors } = useTheme()

  return (
    <MuiTableBody
      {...getTableBodyProps()}
      sx={{
        '& .MuiTableCell-root': {
          color: colors.black,
          fontSize: '14px',
          lineHeight: '16px',
          paddingBottom: '10px',
          border: 'none'
        }
      }}
    >
      {rows.map((row) => {
        prepareRow(row)
        return (
          <TableRow {...row.getRowProps()}>
            {row.cells.map((cell, index) => (
              <TableCell {...cell.getCellProps({
                style: {
                  borderTop: row.depth ? 'none' : `1px solid ${colors.grey.primary}`,
                  paddingTop: !row.depth ? '10px' : '0',
                  paddingRight: 0,
                  paddingLeft: !index ? getSubRowPudding(row.depth) : 0
                }
              })}
              >
                {
                  !index && row.canExpand
                    ? (
                      <CellWrapperStyled>
                        <ExpandButton
                          onClick={() => row.toggleRowExpanded()}
                          isRowExpanded={row.isExpanded}
                        />
                        {cell.render('Cell')}
                      </CellWrapperStyled>
                    )
                    : cell.render('Cell')
                }
              </TableCell>
            ))}
          </TableRow>
        )
      })}
    </MuiTableBody>
  )
}

export default TableBody
