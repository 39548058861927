import { useEffect, useState } from 'react'
import { useAuthContext } from '../state/useAuth'
import { useParsingStatusQuery } from '../queries/useParsingStatusQuery'

const useUploadingStatus = () => {
  const [isUploading, setIsUploading] = useState(false)
  const [isParsing, setIsParsing] = useState(true)
  const { authData } = useAuthContext()
  const { data, error } = useParsingStatusQuery(authData?.userRole !== 'admin' && isParsing)
  const { status: parsingStatus } = data || {}

  useEffect(() => {
    if (authData?.userRole !== 'admin' && (parsingStatus === 'IN_PROCESS' || parsingStatus === 'FILE_UPLOADING')) {
      setIsParsing(false)
      setIsUploading(true)
      return
    }

    if (error) setIsParsing(false)

    setIsUploading(false)
  }, [authData, parsingStatus, error])

  return isUploading
}

export default useUploadingStatus
