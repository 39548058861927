import { useMutation } from 'react-query'
import { apiClient } from '../../../../api/apiClient'

type TProps = { formData: FormData, signal?: AbortSignal }

const uploadFile = async ({ formData, signal }: TProps): Promise<any> => await apiClient.post(
  '/files/uploadFile',
  formData,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    signal
  }
)

export const UPLOAD_FILE_MUTATION_KEY = 'uploadFile'

export const useFileUploadMutation = () => useMutation(
  UPLOAD_FILE_MUTATION_KEY,
  uploadFile
)
