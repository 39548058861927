import { useMemo } from 'react'
import { useUpdatesContext } from '../state/useUpdates'
import { useSearchContext } from '../state/useSearch'
import { filterUpdates } from '../utils'

export const useFilteredUpdates = () => {
  const { updates } = useUpdatesContext()
  const { search } = useSearchContext()

  return useMemo(
    () => filterUpdates(search, updates ?? []),
    [search, updates]
  )
}
