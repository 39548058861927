import { useState } from 'react'
import constate from 'constate'

const useSearch = () => {
  const [search, setSearch] = useState('')

  return {
    search,
    setSearch
  }
}

export const [SearchProvider, useSearchContext] = constate(useSearch)
