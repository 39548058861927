import styled from 'styled-components'
import { ToggleButtonGroup } from '@mui/material'
import React, { FC } from 'react'
import ToggleButton from '../ToggleButton'
import Tooltip from '../Tooltip'
import { TERM_TEXT } from './constants'
import { TFilterShortName } from '../../features/datasets/definitions'

const WrapperStyled = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const TextStyled = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #373b3e;
`

export type TLogic = 'AND' | 'OR'

type TProps = {
  value: TLogic
  handleChange: (logic: TLogic) => void
  className?: string
  shortName: TFilterShortName
}

const LogicToggle: FC<TProps> = ({ handleChange, value, shortName, className }) => {
  const onChange = (event: React.MouseEvent<HTMLElement>, value: TLogic | null) => {
    if (!value) {
      return
    }

    handleChange(value)
  }
  return (
    <WrapperStyled className={className}>
      <TextStyled>Search logic:</TextStyled>
      <ToggleButtonGroup exclusive value={value} onChange={onChange}>
        <Tooltip
          placement="top"
          title={`“Or” logic includes datasets with any of the selected ${TERM_TEXT[shortName]}`}
        >
          <div>
            <ToggleButton value="OR">OR</ToggleButton>
          </div>
        </Tooltip>

        <Tooltip
          placement="top"
          title={`“And” logic includes only datasets with all of the selected ${TERM_TEXT[shortName]}`}
        >
          <div>
            <ToggleButton value="AND">AND</ToggleButton>
          </div>
        </Tooltip>
      </ToggleButtonGroup>
    </WrapperStyled>
  )
}

export default LogicToggle
