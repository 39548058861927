import { FC } from 'react'
import { CellProps } from 'react-table'
import { TRowData } from '../definitions'
import Checkbox from '../../../shared/Checkbox'
import { useComparedVariablesContext } from '../../valuesComparison/state/useComparedVariablesState'
import { getParentRow } from '../../../../utils/table'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'

const VariableSelectCell: FC<CellProps<TRowData>> = ({ row, rowsById }) => {
  const { comparedVariables, addVariable, removeVariable } = useComparedVariablesContext()
  const isSelected = comparedVariables.some(({ variableId }) => variableId === row.original.variableId)
  const parentRow = getParentRow(row, rowsById).original as Required<TRowData>
  const [keywordData] = useKeywordsStateContext().comparedKeywords

  const handleClick = () => {
    if (!isSelected) {
      addVariable({
        variableId: row.original.variableId,
        keyword: keywordData.name,
        acrossDatasets: true,
        dataset: {
          name: parentRow.name,
          shortName: parentRow.shortName,
          id: parentRow.id
        }
      })
      return
    }
    removeVariable(row.original.variableId)
  }

  return <Checkbox checked={isSelected} onChange={handleClick} />
}

export default VariableSelectCell
