import { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useFiltersStateContext } from '../state/useFiltersState'
import { useFiltersQuery } from '../queries/useFiltersQuery'
import { useDatasetsMutation } from '../queries/useDatasetsMutation'
import { prepareFiltersForSubmit, splitKeywords, trackDatasets } from '../utils'
import { useKeywordsStateContext } from '../state/useKeywordsState'
import { TDataset } from '../definitions'
import { DATASETS_QUERY_KEY } from '../queries/useDatasetsQuery'

const useTrackDatasets = () => {
  const [isTrackingAvailable, setIsTrackingAvailable] = useState(false)
  const datasets = useQueryClient().getQueryData<TDataset[]>(DATASETS_QUERY_KEY)

  useEffect(() => {
    // Prevent repeated tracking during the mounting stage
    if (datasets && !isTrackingAvailable) {
      setIsTrackingAvailable(true)
    }
  }, [datasets])

  return (datasets: TDataset[]) => {
    if (isTrackingAvailable) {
      trackDatasets(datasets)
    }
  }
}

export const useDatasetsUpdate = () => {
  const { getSelectedFiltersCount, filters: selectedFilters } = useFiltersStateContext()
  const selectedFiltersCount = getSelectedFiltersCount()
  const filtersData = useFiltersQuery(false).data
  const { mutateAsync, isLoading } = useDatasetsMutation()
  const { submittedKeywords } = useKeywordsStateContext()
  const trackResponse = useTrackDatasets()
  const abortControllerRef = useRef<AbortController | null>(null)

  const fetchData = async () => {
    abortControllerRef.current = new AbortController()
    const response = await mutateAsync({
      data: {
        filters: filtersData ? prepareFiltersForSubmit(selectedFilters, filtersData) : [],
        keywords: splitKeywords(submittedKeywords)
      },
      signal: abortControllerRef.current.signal
    })
    if (response) {
      trackResponse(response.flatMap(({ dataset }) => dataset))
    }
  }

  useEffect(() => {
    if (filtersData?.length && (selectedFiltersCount || submittedKeywords)) {
      fetchData()
    }
  }, [selectedFilters, filtersData, submittedKeywords])

  return {
    isUpdating: isLoading,
    abortController: abortControllerRef.current
  }
}
