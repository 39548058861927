import constate from 'constate'
import { useState } from 'react'
import { TFilterShortName } from '../definitions'
import { TLogic } from '../../../shared/logicToggle/LogicToggle'

export type TFiltersState = Partial<
  Record<
    TFilterShortName,
    {
      filters: string[]
      logic?: TLogic
    }
  >
>

const useFiltersState = () => {
  const [filters, setFilterValue] = useState<TFiltersState>({})
  return {
    setFilterValues: (name: TFilterShortName, values: string[], logic?: TLogic) =>
      setFilterValue({
        ...filters,
        [name]: {
          filters: values,
          logic
        }
      }),
    getFilterValues: (name: TFilterShortName) => filters[name] ?? [],
    clearFilterValues: (name: TFilterShortName) =>
      setFilterValue({
        ...filters,
        [name]: {}
      }),
    clearAll: () => setFilterValue({}),
    getSelectedFiltersCount: () =>
      Object.values(filters).reduce(
        (
          acc,
          curr
          // eslint-disable-next-line no-unsafe-optional-chaining
        ) => acc + (curr?.filters?.length ?? 0),
        0
      ),
    filters
  }
}

export const [FiltersStateProvider, useFiltersStateContext] = constate(useFiltersState)
