import styled from 'styled-components'
import Card from '../../shared/Card'

const PageNameStyled = styled.h1`
  color: ${({ theme }) => theme.colors.grey.dark};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`

const CardStyled = styled(Card)`
  padding: 20px;
  color: ${({ theme }) => theme.colors.black};
  max-width: 640px;
  margin: auto;
`

const TitleStyled = styled.h1<{ $margin?: string }>`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: ${({ $margin }) => $margin};
`

const ItalicSubTitleStyled = styled.span`
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
`

const SubTitleStyled = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`

const TextStyled = styled.p<{ $margin?: string }>`
  font-size: 14px;
  line-height: 18px;
  margin: ${({ $margin }) => $margin};
  white-space: pre-wrap;
`

const ListStyled = styled.ul`
  font-size: 14px;
  line-height: 18px;
`

const ListItemStyled = styled.li`
  &::marker {
    content: '';
  }

  &::before {
    content: '•';
    vertical-align: middle;
    margin: 0 8px;
  }
`

const ParagraphStyled = styled.section<{ $margin?: string }>`
  margin: ${({ $margin }) => $margin};
`

const LinkStyled = styled.a`
&&{
    text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
    color: ${({ theme }) => theme.colors.blue.primary};
    cursor: pointer;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
    background: transparent;
    &:hover{
        text-decoration: underline;
        background: transparent;
        text-decoration-color: ${({ theme }) => theme.colors.blue.dark};
        color: ${({ theme }) => theme.colors.blue.dark};
    }
}
`

const About = () => (
  <CardStyled>
    <PageNameStyled>About</PageNameStyled>

    <ParagraphStyled $margin="16px 0 24px">
      <TitleStyled>Health Data Select</TitleStyled>
      <ItalicSubTitleStyled>Helping researchers find the data they need.</ItalicSubTitleStyled>
      <TextStyled $margin="12px 0 0">
        Health Data Select (HDS) is a searchable database tool that helps users find data to support their analytic requirements. It is
        currently in a beta testing cycle, and feedback is being collected from a select group of users.
      </TextStyled>
    </ParagraphStyled>

    <ParagraphStyled $margin="24px 0">
      <TitleStyled $margin="0 0 12px">Users are able to:</TitleStyled>
      <ListStyled>
        <ListItemStyled>Explore and compare datasets by 14 key characteristics</ListItemStyled>
        <ListItemStyled>Identify variables related to specified keywords</ListItemStyled>
        <ListItemStyled>Determine the possible values available for each variable</ListItemStyled>
      </ListStyled>
    </ParagraphStyled>

    <ParagraphStyled $margin="24px 0">
      <TitleStyled $margin="0 0 8px">Datasets Included in Health Data Select</TitleStyled>
      <TextStyled $margin="0 0 16px">
        HDS currently contains the most recent metadata for 27 top datasets for use in health services research in the United States. The
        determination of which datasets to include is based on several factors:
      </TextStyled>
      <SubTitleStyled>Recognition by authoritative sources</SubTitleStyled>
      <TextStyled $margin="8px 0 24px">
        To be considered for inclusion, datasets must be recognized by at least one authoritative source in the realm of health services
        research. Authoritative sources include relevant government agencies as well as selected libraries and academic institutions.
      </TextStyled>
      <SubTitleStyled>Objective measures of acceptance</SubTitleStyled>
      <TextStyled $margin="8px 0 24px">
        Where possible, objective measures of acceptance and impact are applied. This is an evolving process and incudes factors such as the
        extent of recognition by authoritative sources and the number of citations in scholarly journals.
      </TextStyled>
      <SubTitleStyled>Other inclusion/exclusion criteria</SubTitleStyled>
      <TextStyled $margin="8px 0 0">
        The current tool includes only datasets that are ongoing, US-focused, and publicly available.
        {'\n\n'}
        The existing composition of datasets also reflects an effort to include different types of data. For example, datasets with
        different collection mechanisms (surveys, administrative, and the like) are included. Similarly, datasets with varying content focus
        (healthcare datasets as well as non-healthcare with relevant health variables) are included.
        {'\n\n'}
        As a rule, the reported metadata is based on the most granular version of a dataset. And while in most cases this is the microdata,
        there are multiple entries for which aggregated data is the most granular version available.
      </TextStyled>
    </ParagraphStyled>

    <ParagraphStyled $margin="24px 0 30px">
      <TitleStyled $margin="0 0 8px">Future development</TitleStyled>
      <SubTitleStyled>Expansion</SubTitleStyled>
      <TextStyled $margin="4px 0 16px">We plan to add new datasets along with past and future iterations of the existing entries.</TextStyled>
      <SubTitleStyled>Features</SubTitleStyled>
      <TextStyled $margin="4px 0 16px">Pending user feedback, we hope to improve and expand on the search features.</TextStyled>
      <SubTitleStyled>Impact factor</SubTitleStyled>
      <TextStyled $margin="4px 0 0">
        We are currently developing an impact factor for datasets similar to existing impact factors for academic journals. This
        may be included in future versions of HDS
      </TextStyled>
    </ParagraphStyled>

    <ParagraphStyled>
      <TitleStyled $margin="0 0 8px">Health Data Select Team</TitleStyled>
      <TextStyled>
        HDS is a project of Stern Consulting LLC (
        <LinkStyled target="_blank" href="https://www.sternconsulting.com">https://www.sternconsulting.com</LinkStyled>
        ), a consulting company that has been providing data-driven support for the healthcare industry for over 20 years. The project
        director is Brian Williams, Stern Consulting’s vice president of analytic services. The web interface for the beta version of HDS
        was developed by Achievion Solutions.
      </TextStyled>
    </ParagraphStyled>
  </CardStyled>
)

export default About
