import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { PARAMS, ROUTES } from '../../../../constants/routes'
import { useDatasetQuery } from '../queries/useDatasetQuery'

export const useFetchDataset = () => {
  const { datasetId } = useParams<PARAMS.DATASET_ID>()
  const navigate = useNavigate()
  const {
    isFetching,
    isError,
    data
  } = useDatasetQuery(datasetId as string)

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.DATASETS)
    }
  }, [isError])

  return {
    isFetching,
    data
  }
}
