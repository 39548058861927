import { useState } from 'react'
import constate from 'constate'
import { TComparedVariable } from '../../../../definitions/variable'

const useComparedVariables = () => {
  const [comparedVariables, setComparedVariables] = useState<TComparedVariable[]>([])
  const [comparedVariable, setComparedVariable] = useState<TComparedVariable>()
  return {
    comparedVariables,
    comparedVariable,
    clear: () => setComparedVariables([]),
    addVariable: (value: TComparedVariable) => setComparedVariables([...comparedVariables, value]),
    setVariable: (value: TComparedVariable) => setComparedVariable(value),
    clearVariable: () => setComparedVariable(undefined),
    removeVariable: (varId: number) => setComparedVariables(comparedVariables.filter(({ variableId }) => variableId !== varId))
  }
}

export const [ComparedVariablesProvider, useComparedVariablesContext] = constate(useComparedVariables)
