import { useRef, useState } from 'react'
import constate from 'constate'
import { isCancel } from 'axios'
import { useFileUploadMutation } from '../queries/useFileUploadMutation'
import { getChunks, getFormDataByChunk } from '../utils'
import { useClearStatusMutation } from '../queries/useClearStatusMutation'

const useFileImport = () => {
  const [isUploading, setIsUploading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { mutateAsync: clearStatus } = useClearStatusMutation()
  const controllerRef = useRef<AbortController | null>(null)
  const { mutateAsync: uploadFile } = useFileUploadMutation()

  const cancelUploading = () => {
    controllerRef.current?.abort()
  }

  const importFile = async (
    file: File,
    onSuccess?: () => void,
    onChunkUpload?: (chunk: number, totalCount: number) => void
  ) => {
    try {
      setIsUploading(true)
      setIsError(false)

      const chunks = getChunks(file)

      for (let i = 0; i < chunks.length; i++) {
        const formData = getFormDataByChunk(chunks[i], i + 1, chunks.length, file.name)
        controllerRef.current = new AbortController()
        await uploadFile({
          formData,
          signal: controllerRef.current.signal
        })
        onChunkUpload?.(i + 1, chunks.length)
      }

      onSuccess?.()
    } catch (e) {
      if (isCancel(e)) {
        await clearStatus()
        return
      }
      setIsError(true)
    } finally {
      setIsUploading(false)
    }
  }

  return {
    importFile,
    isUploading,
    isError,
    cancelUploading
  }
}

export const [ImportProvider, useImportContext] = constate(useFileImport)
