import styled from 'styled-components'
import Card from '../../../shared/Card'
import Info from './Info'
import Form from './Form'
import UserInfoFeedback from './UserInfoFeedback'

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  margin-top: 48px;
    
`

const CardStyled = styled(Card)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 260px max-content 260px;
  padding: 0 20px;
`

const SeparatorStyled = styled.div`
  height: 296px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.blue.light};
  margin: 0 20px;
`

const Login = () => (
  <ContainerStyled>
    <UserInfoFeedback />
    <CardStyled>
      <Info />
      <SeparatorStyled />
      <Form />
    </CardStyled>
  </ContainerStyled>
)

export default Login
