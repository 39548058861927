import { FC } from 'react'
import { TableCell, TableHead as MuiTableHead, TableRow } from '@mui/material'
import { TableInstance } from 'react-table'
import { useTheme } from 'styled-components'

type TProps = {
  tableInstance: TableInstance
}

const TableHead: FC<TProps> = ({ tableInstance: { headerGroups } }) => {
  const { colors } = useTheme()

  return (
    <MuiTableHead
      sx={{
        '& .MuiTableCell-root': {
          padding: '4px 0',
          fontWeight: 500,
          color: colors.black,
          fontSize: '14px',
          lineHeight: '17px',
          border: 'none'
        },
        '& .MuiTableRow-root:last-child .MuiTableCell-root': {
          paddingBottom: '16px',
          paddingRight: '5px'
        }
      }}
    >
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((tableGroup) => (
            <TableCell {...tableGroup.getHeaderProps()}>
              {tableGroup.render('Header')}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </MuiTableHead>
  )
}

export default TableHead
