import constate from 'constate'
import { useState } from 'react'
import { useModal } from '../../../../hooks/useModalState'

const useImportModal = () => {
  const [open, closeModal, openModal] = useModal()
  const [fileName, setFileName] = useState('')
  const [progress, setProgress] = useState(0)

  return {
    open,
    closeModal,
    openModal,
    setFileName,
    fileName,
    progress,
    setProgress
  }
}

export const [ImportModalProvider, useImportModalContext] = constate(useImportModal)
