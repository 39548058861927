import { useMutation } from 'react-query'
import { TAuthData } from '../../../../definitions/auth'
import { apiClient } from '../../../../api/apiClient'
import { useAuthContext } from '../../../../state/useAuth'

export const REFRESH_TOKEN_API_URL = '/auth/refresh-token'

const refreshToken = async (authData?: TAuthData): Promise<TAuthData> => {
  if (!authData?.refresh_token) {
    throw new Error('No refresh token')
  }
  return await apiClient
    .post(
      REFRESH_TOKEN_API_URL,
      { refresh_token: authData.refresh_token }
    )
    .then((response) => ({ ...authData, ...response.data }))
}

const REFRESH_TOKEN_MUTATION_KEY = 'refreshToken'

export const useRefreshTokenMutation = () => {
  const { authData } = useAuthContext()
  return useMutation(
    REFRESH_TOKEN_MUTATION_KEY,
    () => refreshToken(authData)
  )
}
