import styled, { css } from 'styled-components'
import pluralize from 'pluralize'
import React from 'react'
import KeywordsTable from './keywordsTable/KeywordsTable'
import useKeywordsTableData from '../../hooks/useKeywordsTableData'
import Hint from '../../../../shared/hint/Hint'
import { ReactComponent as CheckIcon } from '../../assets/Check.svg'
import HintTitle from './HintTitle'
import { useDatasetsContext } from '../../state/useDatasets'

const ContainerStyled = styled.div`
  position: relative;
  padding-bottom: 16px;
`

const ScrollableContainerStyled = styled.div`
  overflow-x: auto;
  margin-left: 540px;
  border-left: 1px solid ${({ theme }) => theme.colors.grey.primary}
`

const titleStyles = css`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`

const TitleStyled = styled.span`
  ${titleStyles};
  position: sticky;
`

const TitleWrapperStyled = styled.div`
    position: absolute;
    left: 20px;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const DatasetsCountStyled = styled.span`
  ${titleStyles};

`

const TextWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
`

const HeaderStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

const DescriptionWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`

const DescriptionStyled = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.grey.dark};
`

const CheckIconStyled = styled(CheckIcon)`
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
`

const SummaryTextStyled = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.grey.dark};
`

const KeywordsSearchResults = () => {
  const datasetsCount = useKeywordsTableData().length
  const { isLoading } = useDatasetsContext()
  if (isLoading) {
    return <></>
  }
  return (
    <ContainerStyled>
      <ScrollableContainerStyled>
        <TextWrapperStyled>
          <HeaderStyled>
            <TitleStyled>
              Availability of variables for the selected search term(s)
            </TitleStyled>
            <Hint withDefaultColor placement="top" maxWidth={280} title={<HintTitle />} />
          </HeaderStyled>
          <DescriptionWrapperStyled>
            <DescriptionStyled>
              Click on search term to compare across datasets.
            </DescriptionStyled>
            <DescriptionStyled>
              Click on
              {' '}
              <CheckIconStyled />
              {' '}
              to see variables associated with each search term for the selected dataset.
            </DescriptionStyled>
          </DescriptionWrapperStyled>
        </TextWrapperStyled>
        <TitleWrapperStyled>
          <DatasetsCountStyled>
            {`${pluralize('Dataset', datasetsCount, true)} found`}
          </DatasetsCountStyled>
          <SummaryTextStyled>
            Click on dataset link to see summary.
          </SummaryTextStyled>
        </TitleWrapperStyled>
        <KeywordsTable />
      </ScrollableContainerStyled>
    </ContainerStyled>
  )
}

export default KeywordsSearchResults
