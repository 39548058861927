import styled from 'styled-components'
import IconButton from '../../../shared/IconButton'
import { ReactComponent as DeleteIcon } from '../assets/Delete.svg'
import { ReactComponent as EditIcon } from '../assets/Edit.svg'
import DeleteUserModal from './DeleteUserModal'
import EditUserModal from './EditUserModal'
import { useModal } from '../hooks/useModal'

const ContainerStyled = styled.div`
  display: grid;
  grid-column-gap: 18px;
  grid-auto-flow: column;
  justify-content: end;
`

const UserActions = () => {
  const [isDeleteModalOpen, closeDeleteModal, openDeleteModal] = useModal()
  const [isEditModalOpen, closeEditModal, openEditModal] = useModal()

  return (
    <>
      <ContainerStyled>
        <IconButton onClick={openEditModal}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={openDeleteModal}>
          <DeleteIcon />
        </IconButton>
      </ContainerStyled>
      <DeleteUserModal open={isDeleteModalOpen} closeModal={closeDeleteModal} />
      <EditUserModal open={isEditModalOpen} closeModal={closeEditModal} />
    </>
  )
}

export default UserActions
