import { useFiltersStateContext } from '../state/useFiltersState'
import { useDatasetSelectContext } from '../state/useDatasetSelect'
import { useOnUpdate } from '../../../../hooks/useOnUpdate'
import { useKeywordsStateContext } from '../state/useKeywordsState'

const useSelectReset = () => {
  const { getSelectedFiltersCount } = useFiltersStateContext()
  const { clearSelectedIds } = useDatasetSelectContext()
  const { submittedKeywords } = useKeywordsStateContext()
  const selectedFiltersCount = getSelectedFiltersCount()

  useOnUpdate(() => {
    clearSelectedIds()
  }, [selectedFiltersCount, submittedKeywords])
}

export default useSelectReset
