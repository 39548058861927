import styled from 'styled-components'
import { FC } from 'react'
import MenuButton from '../../../../shared/MenuButton'
import Hint from '../../../../shared/hint/Hint'
import FilterModal from './FilterModal'
import { useModal } from '../../../../../hooks/useModalState'
import { ReactComponent as ArrowIcon } from '../../assets/Arrow.svg'
import { ReactComponent as CrossIcon } from '../../assets/Cross.svg'
import { TFilter } from '../../definitions'
import { FILTER_HINTS_MAP } from '../../constants'
import { FilterProvider, useFilterContext } from '../../state/useFilter'
import { useFiltersStateContext } from '../../state/useFiltersState'

const IconsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const CounterStyled = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue.primary};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FilterButton: FC = () => {
  const [open, closeModal, openModal] = useModal()
  const { filterData: { shortName, name } } = useFilterContext()
  const { getFilterValues, clearFilterValues } = useFiltersStateContext()
  const selectedValues = getFilterValues(shortName) as any
  const isActive = !!selectedValues?.filters?.length

  return (
    <>
      <MenuButton
        onClick={openModal}
        startIcon={<Hint placement="top" iconWidth={20} title={FILTER_HINTS_MAP[shortName]} />}
        fullWidth
        isActive={isActive}
        endIcon={(
          <IconsContainerStyled>
            {isActive && !!selectedValues?.filters.length && (
              <>
                <CounterStyled>{selectedValues?.filters.length}</CounterStyled>
                <CrossIcon
                  onClick={(e: any) => {
                    e.stopPropagation()
                    clearFilterValues(shortName)
                  }}
                />
              </>
            )}
            <ArrowIcon />
          </IconsContainerStyled>
        )}
      >
        {name}
      </MenuButton>
      <FilterModal open={open} closeModal={closeModal} />
    </>
  )
}

export default (props: { filterData: TFilter }) => (
  <FilterProvider {...props}>
    <FilterButton />
  </FilterProvider>
)
