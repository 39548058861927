import { useMutation } from 'react-query'
import { TUserForm } from '../definitions'
import { apiClient } from '../../../../api/apiClient'

const createUser = async (data: TUserForm) => await apiClient.post('/users', data)

export const CREATE_USER_MUTATION_KEY = 'createUser'

export const useCreateUserMutation = () => useMutation(
  CREATE_USER_MUTATION_KEY,
  createUser
)
