import { useTheme } from 'styled-components'
import { TableCell, TableRow } from '@mui/material'
import { FC, memo } from 'react'
import { Row } from 'react-table'
import { useRowHeightByCell } from '../../../hooks/useRowHeightByCell'
import { isEvenRow } from '../../../../../../utils/table'

const BodyRow: FC<{ row: Row }> = ({ row }) => {
  const {
    cellRefCallback,
    rowHeight
  } = useRowHeightByCell()
  const { colors } = useTheme()
  return (
    <TableRow
      sx={{ height: rowHeight }}
      {...row.getRowProps()}
    >
      {row.cells.map((cell) => (
        <TableCell
          sx={{
            '&.MuiTableCell-root:first-of-type': {
              paddingLeft: row.depth ? '68px' : undefined
            },
            backgroundColor: isEvenRow(row) ? colors.grey.light : undefined
          }}
          {...cell.getCellProps()}
          ref={cellRefCallback}
        >
          {cell.render('Cell')}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default memo(BodyRow)
