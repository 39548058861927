import { TextareaAutosize } from '@mui/material'
import { Control, RegisterOptions, useController } from 'react-hook-form'
import { FC, memo } from 'react'
import styled from 'styled-components'

const WrapperStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const LabelStyled = styled.label`
  display: block;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.grey.dark};
`

const CustomTextAreaStyled = styled(TextareaAutosize)<{isError?:boolean}>`
    resize: none;
    background: $white;
    border: 1px solid $grey300;
    border-radius: 8px;
    width: 100%;
    height: 100% !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.black};
    padding: 10px 8px;

    &::placeholder{
        color: #768A9A;
        opacity: 1;
        font-weight: 400
    }
    &:focus{
        outline: none !important;
        border: 1px solid $green300;
    }
`

const ErrorTextStyled = styled.div`
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.red.primary};
`

type TProps = {
  name: string
  control: Control<any>
  rules?: RegisterOptions
  label?: string | null
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom',
  placeholder?: string
  minRows?:number
  handleChange?: (value: string) => string | number
}
const CustomTextArea: FC<TProps> = ({
  name,
  control,
  rules,
  label,
  minRows,
  handleChange,
  placeholder
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules
  })
  const errorMessage = fieldState.error?.message
  return (
    <WrapperStyled>
      <LabelStyled>{label}</LabelStyled>
      <CustomTextAreaStyled
        minRows={minRows}
        placeholder={placeholder}
        isError={!!errorMessage}
        {...field}
        onChange={(e) => {
          // eslint-disable-next-line no-unused-expressions
          handleChange ? field.onChange(handleChange(e.target.value)) : field.onChange(e)
        }}
      />
      {!!errorMessage && <ErrorTextStyled>{errorMessage}</ErrorTextStyled>}
    </WrapperStyled>
  )
}

export default memo(CustomTextArea)
