import { FC, useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { useSearchContext } from '../state/useSearch'
import SearchInput from '../../../shared/SearchInput'

type TProps = {
  className?: string
}

const Search: FC<TProps> = ({ className }) => {
  const { setSearch } = useSearchContext()
  const [value, setValue] = useState('')

  const onChange = useAsyncDebounce(setSearch, 250)

  const clearSearch = () => {
    setValue('')
    setSearch('')
  }

  return (
    <SearchInput
      id="search"
      name="search"
      onClear={clearSearch}
      value={value}
      onChange={({ target: { value } }) => {
        setValue(value)
        onChange(value.toLowerCase())
      }}
      placeholder="Search..."
      className={className}
    />
  )
}

export default Search
