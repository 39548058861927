import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import qs from 'qs'
import { CompareButton as MainCompareButton, TCompareButtonProps } from '../../compareButton/CompareButton'
import { ROUTES } from '../../../../constants/routes'
import { useDatasetSelectContext } from '../state/useDatasetSelect'

const CompareButton: FC<TCompareButtonProps> = (props) => {
  const navigate = useNavigate()
  const { selectedIds } = useDatasetSelectContext()

  const handleClick = () => {
    const params = qs.stringify(
      { datasets: selectedIds },
      { arrayFormat: 'comma' }
    )
    navigate(`${ROUTES.COMPARE_DATASETS}?${params}`)
  }

  return (
    <MainCompareButton {...props} onClick={handleClick} />
  )
}

export default CompareButton
