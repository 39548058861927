import axios, { AxiosError } from 'axios'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { REVOKE_TOKEN_API_URL } from '../../logout/queries/useRevokeTokenMutation'
import { apiClient } from '../../../../api/apiClient'
import { ROUTES } from '../../../../constants/routes'
import { useAuthContext } from '../../../../state/useAuth'
import { setAuthorizationHeader } from '../utils'
import { REQUEST_TOKEN_API_URL } from '../../login/queries/useLoginMutation'
import { REFRESH_TOKEN_API_URL, useRefreshTokenMutation } from '../queries/useRefreshTokenMutation'

const isRefreshNeeded = ({ response }: AxiosError) =>
  response
  && !response.request.responseURL.includes(REVOKE_TOKEN_API_URL)
  && !response.request.responseURL.includes(REFRESH_TOKEN_API_URL)
  && !response.request.responseURL.includes(REQUEST_TOKEN_API_URL)
  && response.status === 401

function useTokenRefresh(): () => Promise<string> {
  const { mutateAsync: refreshToken } = useRefreshTokenMutation()
  const { setAuthData } = useAuthContext()

  // Status if refresh token loading started
  const isUpdating = useRef(false)
  // Token after update cache
  const tokenAfterUpdate = useRef<string | null>(null)

  // There is situation when we perform several application requests simultaneously.
  // This code exists to perform ONLY one token refreshing request
  return async () => {
    // Perform single refresh token request
    if (!isUpdating.current) {
      isUpdating.current = true
      try {
        // Refresh request
        const authData = await refreshToken()
        // Setting of new auth data
        setAuthData(authData)

        // Setting token to cache to use in awaiting usecase
        tokenAfterUpdate.current = authData.access_token

        // Return new token
        return authData.access_token
      } finally {
        // Clear flag in any case
        isUpdating.current = false
      }
    }

    // Await for token refreshing request
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        // Checking process flag
        if (!isUpdating.current) {
          clearInterval(interval)

          // return new token from cache
          resolve(tokenAfterUpdate.current as string)
        }
      }, 100)
    })
  }
}

const useResponseErrorInterceptor = () => {
  const navigate = useNavigate()
  const { removeAuthData } = useAuthContext()
  const refreshToken = useTokenRefresh()

  return useCallback(
    async (error: AxiosError) => {
      if (axios.isAxiosError(error)) {
        if (!isRefreshNeeded(error)) {
          return Promise.reject(error)
        }

        // @ts-ignore
        if (error.config && !error.config._retry) {
          // @ts-ignore
          error.config._retry = true

          try {
            const accessToken = await refreshToken()
            setAuthorizationHeader(error.config, accessToken as string)
            return axios(error.config)
          } catch (e) {
            removeAuthData()
            navigate(ROUTES.LOGIN)
          }
        }
      }
    },
    [refreshToken]
  )
}

export const useOtherErrorsHandling = () => {
  const navigate = useNavigate()
  return useCallback(async ({ response, code }: AxiosError) => {
    if (code === 'ERR_CANCELED') return

    if (!response) {
      navigate(ROUTES.MAINTENANCE)
      return
    }

    const statusUrl = response?.config?.url === '/parse/status'

    if (response.status > 401 && !statusUrl) {
      navigate(ROUTES.MAINTENANCE)
    }
  }, [])
}

export const useResponseInterceptor = () => {
  const errorInterceptor = useResponseErrorInterceptor()
  const otherErrorsHandling = useOtherErrorsHandling()

  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        await otherErrorsHandling(error)
        return await errorInterceptor(error)
      }
    )
    return () => {
      if (interceptor) {
        apiClient.interceptors.request.eject(interceptor)
      }
    }
  }, [errorInterceptor, otherErrorsHandling])
}
