import { FC } from 'react'
import { Control, useController } from 'react-hook-form'
import Select, { TSelectProps } from '../select/Select'

type TProps = {
  control: Control<any>
  name: string
} & TSelectProps

const FormSelect: FC<TProps> = ({ control, name, ...rest }) => {
  const { field } = useController({
    control,
    name
  })

  return (
    <Select {...field} {...rest} />
  )
}

export default FormSelect
