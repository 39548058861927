import React from 'react'
import styled from 'styled-components'
import Opportunities from './Opportunities'

const ContainerStyled = styled.div`
  color: ${({ theme }) => theme.colors.black};
`

const TitleStyled = styled.span`
  display: block;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 2px;
`

const AppNameStyled = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`

const DescriptionStyled = styled.span`
  font-size: 14px;
  max-width: 220px;
  font-style: italic;
  line-height: 20px;
  display: block;
  margin: 8px 0 16px 0;
`

const Info = () => (
  <ContainerStyled>
    <TitleStyled>Welcome to</TitleStyled>
    <AppNameStyled>Health Data Select</AppNameStyled>
    <DescriptionStyled>
      Helping researchers find the data they need.
    </DescriptionStyled>
    <Opportunities />
  </ContainerStyled>
)

export default Info
