import { useLocation } from 'react-router-dom'

export const useOpenDataset = (id?: number) => {
  const location = useLocation()

  return {
    to: `/datasets/${id}`,
    state: { from: location.pathname }
  }
}
