import { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as WarningLineIcon } from '../../../../assets/WarningLine.svg'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const MessageStyled = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: ${({ theme }) => theme.colors.black};
    margin-left: 10px;
    width: 100%;
    max-width: 335px;
`

type TProps = {
  message: string
}

const ErrorNotification: FC<TProps> = ({ message }) => (
  <Wrapper>
    <WarningLineIcon width={20} height={20} />
    <MessageStyled>{message}</MessageStyled>
  </Wrapper>
)

export default ErrorNotification
