import { Row } from 'react-table'

export const isEvenRow = (row: Row) => {
  const [mainRowId] = row.id.split('.')
  return +mainRowId % 2 !== 0
}

export const getPagesCount = (rowsCount: number, rowsPerPage: number) => (rowsCount ? Math.ceil(rowsCount / rowsPerPage) : 1)
export const sliceRows = (page: number, rowsPerPage: number, rows: any[]) => rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)

export const getParentRow = <T extends object>(subRow: Row<T>, rowsById: Record<string, Row<T>>): Row<T> => {
  const [mainRowId] = subRow.id.split('.')
  return rowsById[mainRowId]
}

export const getPrevRowIndex = <T extends object>(rows: Row<T>[], rowId: string) => {
  const currentRowIndex = rows.findIndex((row) => row.id === rowId)

  return currentRowIndex - 1
}
