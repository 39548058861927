import { useQuery } from 'react-query'
import { apiClient } from '../../../../api/apiClient'
import { TFilter } from '../definitions'

const getFilters = async (): Promise<TFilter[]> => await apiClient
  .get('/datasets/filters')
  .then((response) => response?.data)

export const FILTERS_QUERY_KEY = 'filters'

export const useFiltersQuery = (enabled = true) => useQuery(
  FILTERS_QUERY_KEY,
  getFilters,
  {
    enabled,
    cacheTime: 0
  }
)
