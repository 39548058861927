import { useQuery } from 'react-query'
import { apiClient } from '../../../../api/apiClient'
import { TDataset } from '../definitions'
import { useAuthContext } from '../../../../state/useAuth'

const getDatasets = async (accessToken?:string): Promise<TDataset[]> => await apiClient
  .get('/datasets', {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    }
  })
  .then((response) => response?.data)

export const DATASETS_QUERY_KEY = 'datasetsCompare'

export const useDatasetsQuery = (enabled?: boolean) => {
  const accessToken = useAuthContext().authData?.access_token
  return useQuery(
    DATASETS_QUERY_KEY,
    () => getDatasets(accessToken),
    {
      enabled,
      cacheTime: 0
    }
  )
}
