import React, { FC } from 'react'
import { ToggleButton as MuiToggleButton, ToggleButtonProps } from '@mui/material'
import { useTheme } from 'styled-components'

const ToggleButton: FC<ToggleButtonProps> = ({ sx, ...rest }) => {
  const { colors } = useTheme()

  return (
    <MuiToggleButton
      {...rest}
      sx={{
        height: '36px',
        textTransform: 'none',
        color: colors.grey.dark,
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontSize: '14px',
        padding: '6px 16px',
        '&:hover': {
          backgroundColor: colors.grey.light
        },
        '&.Mui-selected': {
          backgroundColor: colors.blue.primary,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.blue.dark
          }
        },
        ...sx
      }}
    />
  )
}

export default ToggleButton
