import { useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { useCreateUserMutation } from '../queries/useCreateUserMutation'
import { TUserForm } from '../definitions'
import { USERS_QUERY_KEY } from '../queries/useUsersQuery'

export const useCreateUser = (onSuccess: () => void, onError: (error: AxiosError) => void) => {
  const {
    mutateAsync,
    isLoading
  } = useCreateUserMutation()
  const queryClient = useQueryClient()

  const createUser = async (data: TUserForm) => {
    try {
      await mutateAsync(data)
      onSuccess()
      await queryClient.invalidateQueries(USERS_QUERY_KEY)
    } catch (error) {
      onError(error as AxiosError)
    }
  }

  return {
    createUser,
    isLoading
  }
}
