import styled from 'styled-components'
import React, { useRef } from 'react'
import Button from '../../../shared/Button'
import { useUpdatesContext } from '../state/useUpdates'
import { useImportContext } from '../state/useFileImport'
import { useImportModalContext } from '../state/useImportModal'
import { getProgressByChunk } from '../utils'

const InputStyled = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 100%;
`

const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleStyled = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
`

const Header = () => {
  const { importFile, isUploading } = useImportContext()
  const { fetchUpdates, isFetching } = useUpdatesContext()
  const { setFileName, setProgress } = useImportModalContext()
  const inputRef = useRef<HTMLInputElement>(null)

  const resetInput = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0)
    if (!file) return
    resetInput()
    setFileName(file.name)
    setProgress(0)
    await importFile(
      file,
      fetchUpdates,
      (chunk, totalCount) => setProgress(getProgressByChunk(chunk, totalCount))
    )
  }

  return (
    <ContainerStyled>
      <InputStyled
        accept=".accdb, .mdb"
        onChange={handleImport}
        type="file"
        ref={inputRef}
      />
      <TitleStyled>Data Management</TitleStyled>
      <Button disabled={isFetching || isUploading} onClick={() => inputRef.current?.click()}>
        Import
      </Button>
    </ContainerStyled>
  )
}

export default Header
