import { FC, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Modal, { TModelProps } from '../../../../shared/modal/Modal'
import ModalTitle from '../../../../shared/modal/ModalTitle'
import ModalContent from '../../../../shared/modal/ModalContent'
import ModalActions from '../../../../shared/modal/ModalActions'
import Button from '../../../../shared/Button'
import Checkbox from '../../../../shared/Checkbox'
import { useFilterContext } from '../../state/useFilter'
import { filterVariants, filterVariantsByRange, getOptionsByYears, isDateFilter, isLogicFilter, trackFilter } from '../../utils'
import { useFiltersStateContext } from '../../state/useFiltersState'
import SearchInput from '../../../../shared/SearchInput'
import YearsRange, { YearsRangeMethods } from '../../../../shared/yearsRange/YearsRange'
import { useFilterState } from '../../hooks/useFilterState'
import LogicToggle from '../../../../shared/logicToggle/LogicToggle'

const CheckboxContainerStyled = styled.div`
  display: grid;
  grid-row-gap: 8px;
  margin-top: 12px;
  overflow-x: hidden;
`

const CounterStyled = styled.span`
  color: ${({ theme }) => theme.colors.grey.dark};
  font-size: 18px;
  font-weight: 400;
`

const NoResultsMessageStyled = styled.div`
  margin-top: 12px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`

const YearsRangeStyled = styled(YearsRange)`
  padding: 12px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey.primary}`};
`

const DateFilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const LogicStyled = styled(LogicToggle)`
  padding: 12px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey.primary}`};
`

const FilterModal: FC<TModelProps> = ({ closeModal, open, ...rest }) => {
  const {
    filterData: { name, value, shortName }
  } = useFilterContext()
  const [searchValue, setSearchValue] = useState('')
  const { selectValue, selectedValues, isValueSelected, initState, clearState, setSelectedValues, logic, setLogic } = useFilterState()
  const { setFilterValues } = useFiltersStateContext()
  const rangeRef = useRef<YearsRangeMethods>(null)

  useLayoutEffect(() => {
    if (open) {
      initState()
    }
  }, [open])

  const filteredVariants = filterVariants(value, searchValue)

  const closeModalHandler = () => {
    setSearchValue('')
    clearState()
    closeModal?.()
  }

  const handleApply = () => {
    setFilterValues(shortName, selectedValues, isLogicFilter(shortName) ? logic : undefined)
    trackFilter(name, selectedValues)
    closeModalHandler()
  }

  const handleSelect = (value: string) => {
    selectValue(value)
    rangeRef.current?.clear()
  }

  const handleRangeChange = ([from, to]: string[]) => {
    if (from && to) {
      setSelectedValues(filterVariantsByRange(filteredVariants, [from, to]))
    }
  }

  return (
    <Modal {...rest} open={open} closeModal={closeModalHandler} withCloseButton>
      <ModalTitle sx={{ paddingBottom: '16px' }}>
        {name}
        <CounterStyled>
          {' '}
          ({/* eslint-disable-next-line no-unsafe-optional-chaining */}
          {selectedValues?.length} selected)
        </CounterStyled>
      </ModalTitle>
      <ModalContent sx={{ paddingBottom: '8px' }}>
        <SearchInput
          fullWidth
          onClear={() => setSearchValue('')}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder="Search..."
        />
        <DateFilterWrap>
          {isLogicFilter(shortName) && <LogicStyled value={logic} handleChange={(logic) => setLogic(logic)} shortName={shortName} />}

          {isDateFilter(shortName) && (
            <YearsRangeStyled ref={rangeRef} options={getOptionsByYears(filteredVariants)} onChange={handleRangeChange} />
          )}
        </DateFilterWrap>
        {!filteredVariants.length ? (
          <NoResultsMessageStyled>No results were found</NoResultsMessageStyled>
        ) : (
          <CheckboxContainerStyled>
            {filteredVariants.map((variant) => (
              <Checkbox
                checked={isValueSelected(variant)}
                onChange={() => handleSelect(variant)}
                key={variant}
                label={variant}
                sx={{ width: 'fit-content' }}
              />
            ))}
          </CheckboxContainerStyled>
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={closeModalHandler}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleApply}>
          Apply
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default FilterModal
