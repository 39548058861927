import { useMemo } from 'react'
import { useDatasetsContext } from '../state/useDatasets'
import { prepareDatasetsToDisplay } from '../utils'

const useKeywordsTableData = () => {
  const { datasets, isLoading } = useDatasetsContext()
  return useMemo(
    () => (
      datasets
        ? prepareDatasetsToDisplay(datasets, 'keywords')
        : []
    ),
    [datasets, isLoading]
  )
}

export default useKeywordsTableData
