import { useMutation } from 'react-query'
import { apiClient } from '../../../../api/apiClient'

const deleteUser = async (userId: string): Promise<any> => await apiClient.delete(`/users/${userId}`)

export const DELETE_USER_MUTATION_KEY = 'deleteUser'

export const useDeleteUserMutation = () => useMutation(
  DELETE_USER_MUTATION_KEY,
  deleteUser
)
