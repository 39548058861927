import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './AppRoutes'
import GlobalStyles from './styles/GlobalStyles'
import { theme } from './styles/theme'
import { queryClient } from './api/queryClient'
import './styles/font.css'
import { AuthProvider } from './state/useAuth'
import ApiInterceptors from './components/features/apiInterceptors/components/ApiInterceptors'
import GoogleAnalytic from './components/features/googleAnalytic/components/GoogleAnalytic'
import { NotificationProvider } from './components/features/notifications/state/useNotification'
import Notification from './components/features/notifications/components/Notifications'
import { NavProvider } from './state/useNavState'

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NotificationProvider>
          <NavProvider>
            <AuthProvider>
              <GoogleAnalytic />
              <GlobalStyles />
              <ApiInterceptors />
              <AppRoutes />
              <Notification />
            </AuthProvider>
          </NavProvider>
        </NotificationProvider>
      </BrowserRouter>
    </ThemeProvider>
  </QueryClientProvider>
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(<App />)
