import styled from 'styled-components'
import React from 'react'
import Card from '../../../shared/Card'
import Header from './Header'
import UsersTable from './UsersTable'
import { useUsersQuery } from '../queries/useUsersQuery'
import { PaginationProvider, usePaginationContext } from '../../../../hooks/usePagination'
import Spinner from '../../../shared/Spinner'
import Pagination from './Pagination'

const SpinnerStyled = styled(Spinner)`
  margin: 6px 0 16px;
`

const CardStyled = styled(Card)`
  padding: 20px 20px 6px;
  margin-bottom: 20px;
  display: grid;
  grid-row-gap: 14px;
`

const UserManagement = () => {
  const { setPagesCount } = usePaginationContext()
  const { isFetching, isFetched } = useUsersQuery(
    true,
    (data) => {
      if (!data) return
      setPagesCount(data.totalPages)
    }
  )
  const isLoading = isFetching || !isFetched

  return (
    <div>
      <CardStyled>
        <Header />
        {
          isLoading
            ? <SpinnerStyled />
            : <UsersTable />
        }
      </CardStyled>
      {!isLoading && <Pagination />}
    </div>
  )
}

export default () => (
  <PaginationProvider>
    <UserManagement />
  </PaginationProvider>
)
