import { useState } from 'react'
import constate from 'constate'
import { useSearchParams } from 'react-router-dom'

type TProps = {
  rowsPerPage?: number
  initialRowsCount?: number
}

type TPagesCount = Record<number, number>

const usePagination = ({ rowsPerPage: initialRowsPerPage = 20, initialRowsCount }: TProps) => {
  const [searchParams] = useSearchParams()
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1)
  const [pagesCount, setPagesCount] = useState(() => Math.ceil(Number(initialRowsCount) / initialRowsPerPage))
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)

  const [pagePerPageRows, setPagePerPageRows] = useState<TPagesCount>({
    1: initialRowsPerPage
  })
  const handleSetPagePerPageRows = (page: number, newRowsPerPage?: number) => {
    setPagePerPageRows((prevState) => ({
      ...prevState,
      [page]: newRowsPerPage || rowsPerPage
    }))
  }

  const handleSetPagePerRows = (newRowsPerPage: number) => {
    const pagesCount = Math.ceil(Number(initialRowsCount) / newRowsPerPage)
    setRowsPerPage(newRowsPerPage)
    setPagesCount(pagesCount)
    handleSetPagePerPageRows(page, newRowsPerPage)
    if (page > pagesCount) {
      setPage(pagesCount)
    }
  }

  const startCount = Array(page)
    .fill('')
    .map((_, i) => i + 1)
    .reduce((acc, prevPages) => {
      if (page > prevPages) {
        acc += pagePerPageRows[prevPages] ?? rowsPerPage
      }
      return acc
    }, 0)

  return {
    page,
    handleSetPagePerRows,
    handleSetPagePerPageRows,
    startCount,
    setPage,
    pagesCount,
    setPagesCount,
    rowsPerPage,
    setRowsPerPage
  }
}

export const [PaginationProvider, usePaginationContext] = constate(usePagination)
