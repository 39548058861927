import styled from 'styled-components'
import {
  FC, PropsWithChildren, useCallback, useState
} from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import { useAuthContext } from '../../../state/useAuth'

const ContainerStyled = styled.div<{ $isHeaderVisible?: boolean }>`
  background-color: ${({ theme }) => theme.colors.blue.light};
  min-height: 100%;
  display: grid;
  grid-template-rows: ${({ $isHeaderVisible }) => ($isHeaderVisible ? 'auto 1fr' : '1fr')};
`

const ContentWrapperStyled = styled.div`
  max-width: ${({ theme }) => theme.maxContentWidth};
  width: 100%;
  justify-self: center;
  padding: 20px;
`

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const isHeaderVisible = !!useAuthContext().authData?.access_token
  const [render, setRender] = useState(false)

  const handleRender = useCallback(() => setRender((prevState) => !prevState), [])
  return (
    <ContainerStyled $isHeaderVisible={isHeaderVisible}>
      {isHeaderVisible && <Header handleRender={handleRender} />}
      <ContentWrapperStyled key={Number(render).toString()}>
        {children}
      </ContentWrapperStyled>
      <Footer />
    </ContainerStyled>
  )
}

export default Layout
