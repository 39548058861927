import constate from 'constate'
import useLocalStorage from '../hooks/useLocalStorage'
import { TAuthData } from '../definitions/auth'

export const AUTH_LOCAL_STORAGE_KEY = 'user'

const useAuth = () => {
  const [authData, setAuthData, removeAuthData] = useLocalStorage<TAuthData>(AUTH_LOCAL_STORAGE_KEY)

  return {
    authData,
    setAuthData,
    removeAuthData
  }
}

export const [AuthProvider, useAuthContext] = constate(useAuth)
