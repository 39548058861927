import { FC, useLayoutEffect } from 'react'
import { CellProps } from 'react-table'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { TRowData } from '../definitions'
import { useComparedVariablesContext } from '../../valuesComparison/state/useComparedVariablesState'
import { getParentRow } from '../../../../utils/table'
import { ROUTES } from '../../../../constants/routes'
import { useDatasetData } from '../hooks/useDatasetData'
import { TDatasetBaseData } from '../../../../definitions/dataset'

const LinkStyled = styled(NavLink)`
&&{
    text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
    color: ${({ theme }) => theme.colors.blue.primary};
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
    background: transparent;
    &:hover{
        text-decoration: underline;
        background: transparent;
        text-decoration-color: ${({ theme }) => theme.colors.blue.dark};
        color: ${({ theme }) => theme.colors.blue.dark};
    }
}
`

const VariableCodeCell: FC<CellProps<TRowData>> = ({ row, rowsById, value }) => {
  const { setVariable, clearVariable } = useComparedVariablesContext()
  const { variableId } = (row.original as TRowData)
  const dataset = useDatasetData() as TDatasetBaseData
  const { keyword } = (getParentRow(row, rowsById).original as TRowData)

  useLayoutEffect(() => {
    clearVariable()
  }, [])

  const handleClick = () => {
    setVariable({
      keyword: keyword as string,
      variableId,
      acrossDatasets: false,
      dataset
    })
  }

  return (
    <LinkStyled to={ROUTES.COMPARE_VALUES} onClick={handleClick}>
      {value}
    </LinkStyled>
  )
}

export default VariableCodeCell
