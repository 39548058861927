import {
  FC, forwardRef, useEffect, useImperativeHandle, useMemo, useState
} from 'react'
import styled from 'styled-components'
import Select, { TOption } from '../select/Select'

const SELECT_WIDTH = '72px'

const ContainerStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-auto-columns: max-content;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  line-height: 16px;
`

export type YearsRangeMethods = {
  clear: () => void
}

type TProps = {
  className?: string
  onChange?: (value: [string, string]) => void
  options: TOption[]
  ref?: any
}

const YearsRange: FC<TProps> = forwardRef(({ options, onChange, className }, ref) => {
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')

  const secondYearOptions = useMemo(
    () => options.filter((option) => +option.value >= +from),
    [from]
  )

  useImperativeHandle(ref, () => ({
    clear: () => {
      setFrom('')
      setTo('')
    }
  }))

  useEffect(
    () => {
      if (+from > +to) {
        setTo('')
        onChange?.([from, ''])
        return
      }
      onChange?.([from, to])
    },
    [from, to]
  )

  return (
    <ContainerStyled className={className}>
      <span>From</span>
      <Select
        value={from}
        onChange={(event) => setFrom(event.target.value as string)}
        options={options}
        sx={{ width: SELECT_WIDTH }}
      />
      <span>To</span>
      <Select
        value={to}
        onChange={(event) => setTo(event.target.value as string)}
        options={secondYearOptions}
        sx={{ width: SELECT_WIDTH }}
      />
    </ContainerStyled>
  )
})

export default YearsRange
