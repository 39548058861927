import { CellProps } from 'react-table'
import styled from 'styled-components'
import { TRowData } from '../definitions'
import IconButton from '../../../shared/IconButton'
import Link from '../../../shared/Link'
import { ReactComponent as MinusIcon } from '../assets/Minus.svg'
import { ReactComponent as PlusIcon } from '../assets/Plus.svg'
import { useOpenDataset } from '../../../../hooks/useOpenDataset'

const NameStyled = styled.span<{ $isSubRow?: boolean }>`
  vertical-align: middle;
`

const NameCell = ({ value, row }: CellProps<TRowData>) => {
  const { to, state } = useOpenDataset(row.original.id)

  return (
    <>
      {row.canExpand && (
        <IconButton
          sx={{
            marginRight: '8px',
            width: '20px',
            height: '20px'
          }}
          onClick={() => row.toggleRowExpanded()}
        >
          {
            row.isExpanded
              ? <MinusIcon />
              : <PlusIcon />
          }
        </IconButton>
      )}
      <NameStyled $isSubRow={!!row.depth}>
        {value}
        {row.original.short_name && (
          <>
            <span> - </span>
            <Link
              sx={{ fontWeight: 400 }}
              to={to}
              state={state}
            >
              {row.original.short_name}
            </Link>
          </>
        )}
      </NameStyled>
    </>
  )
}

export default NameCell
