import { FC } from 'react'
import { Control, RegisterOptions, useController } from 'react-hook-form'
import TextField, { TTextFieldProps } from '../TextField'

type TProps = {
  control: Control<any>
  name: string
  rules?: RegisterOptions
  trim?: boolean
  handleChange?: (value: string) => string | number
} & TTextFieldProps

const FormTextField: FC<TProps> = ({
  control,
  name,
  rules,
  trim,
  handleChange,
  ...rest
}) => {
  const { field, fieldState: { error, invalid } } = useController({
    control,
    name,
    rules
  })

  return (
    <TextField
      error={invalid}
      helperText={error?.message}
      {...rest}
      {...field}
      onChange={(e) => {
        // eslint-disable-next-line no-unused-expressions
        handleChange
          ? field.onChange(handleChange(trim ? e.target.value?.trim() : e.target.value))
          : field.onChange(trim ? e.target.value?.trim() : e.target.value)
      }}
    />
  )
}

export default FormTextField
