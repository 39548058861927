import { Navigate, Outlet } from 'react-router-dom'
import { FC, PropsWithChildren } from 'react'
import { useAuthContext } from '../../../state/useAuth'
import { ROUTES } from '../../../constants/routes'

const PublicRoutesGuard: FC<PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useAuthContext().authData?.access_token

  if (isAuthenticated) {
    return <Navigate to={ROUTES.DATASETS} />
  }

  return (
    children
      ? <>{children}</>
      : <Outlet />
  )
}

export default PublicRoutesGuard
