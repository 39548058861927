import { Table as MuiTable } from '@mui/material'
import { Column, useExpanded, useTable } from 'react-table'
import { FC } from 'react'
import TableBody from './TableBody'
import TableHead from './TableHead'

type TProps = {
  columns: Column[],
  data: any[]
}

const Table: FC<TProps> = ({ columns, data }) => {
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useExpanded
  )
  const { getTableProps } = tableInstance

  return (
    <MuiTable {...getTableProps()}>
      <TableHead tableInstance={tableInstance} />
      <TableBody tableInstance={tableInstance} />
    </MuiTable>
  )
}

export default Table
