import { FC } from 'react'
import { useLogout } from '../hooks/useLogout'
import Button from '../../../shared/Button'

const Logout:FC<{ className?: string }> = ({ className }) => {
  const logout = useLogout()

  return (
    <Button
      className={className}
      onClick={logout}
      variant="outlined"
    >
      Log out
    </Button>
  )
}

export default Logout
