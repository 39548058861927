import { Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel as Label } from '@mui/material'
import { FC } from 'react'
import { useTheme } from 'styled-components'

type TProps = {
  label?: string
} & CheckboxProps

const Checkbox: FC<TProps> = ({ label, sx, ...rest }) => {
  const { colors } = useTheme()

  return (
    <Label
      label={label}
      sx={{
        margin: 0,
        '& .MuiTypography-root': {
          color: colors.black,
          fontSize: 14,
          lineHeight: '16px',
          marginLeft: '8px'
        },
        ...sx
      }}
      control={(
        <MuiCheckbox
          {...rest}
          disableRipple
          sx={{
            '& .MuiSvgIcon-root': {
              width: '24px',
              height: '24px'
            },
            color: colors.black,
            '&.MuiButtonBase-root.Mui-checked': {
              color: colors.blue.primary
            },
            '&.MuiButtonBase-root': {
              padding: 0
            }
          }}
        />
      )}
    />

  )
}

export default Checkbox
