import {
  FormControl,
  MenuItem,
  Select as MuiSelect,
  SelectProps
} from '@mui/material'
import { forwardRef, useId } from 'react'
import styled, { useTheme } from 'styled-components'
import { ReactComponent as ArrowDownIcon } from './assets/ArrowDown.svg'

const LabelStyled = styled.label`
  color: ${({ theme }) => theme.colors.grey.dark};
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 16px;
`

export type TOption = {
  label: string
  value: string | number
}

export type TSelectProps = {
  options: TOption[],
  required?: boolean
} & SelectProps

const Select = forwardRef<any, TSelectProps>(({
  label,
  options,
  required,
  sx,
  ...rest
}, ref) => {
  const id = useId()
  const { colors } = useTheme()

  return (
    <FormControl fullWidth>
      {label && (
        <LabelStyled htmlFor={id}>{`${label}${required ? '*' : ''}`}</LabelStyled>
      )}
      <MuiSelect
        {...rest}
        id={id}
        ref={ref}
        IconComponent={ArrowDownIcon}
        MenuProps={{
          sx: {
            '& .MuiList-root': {
              maxHeight: '200px'
            }
          }
        }}
        sx={{
          height: '36px',
          fontSize: '14px',
          lineHeight: '16px',
          color: colors.black,
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 36px 10px 8px'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.grey.dark} !important`
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.blue.primary} !important`,
            borderWidth: '1px'
          },
          '& .MuiSelect-icon': {
            top: 'auto'
          },
          ...sx
        }}
      >
        {options.map(({
          label,
          value
        }) => (
          <MenuItem
            key={value}
            value={value}
            sx={{
              lineHeight: '16px',
              color: colors.black,
              padding: '10px 8px',
              fontSize: '14px',
              '&.Mui-selected': {
                backgroundColor: colors.grey.light,
                '&:hover': {
                  backgroundColor: colors.grey.light
                }
              }
            }}
          >
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
})

export default Select
