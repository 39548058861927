import { TFilterShortName } from '../../features/datasets/definitions'

export const TERM_TEXT: Partial<Record<TFilterShortName, string>> = {
  geo_variables: 'geographic variables',
  record_level: 'record levels',
  mechanism: 'collection mechanisms',
  host_organizations: 'organizations',
  data_available_years: 'years',
  metadata_included_years: 'years'
}
