import styled, { css } from 'styled-components'
import { ReactComponent as CheckIcon } from '../../assets/Check.svg'

const iconStyles = css`
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
`

const HintTitleWrapStyled = styled.div`
`

const CheckIconStyled = styled(CheckIcon)`${iconStyles}`

const HintTitle = () => (
  <HintTitleWrapStyled>
    <CheckIconStyled />
    {' '}
    indicates that the dataset contains one or more variable(s) for the selected search terms(s).
    If a search term yields no results, there will be no corresponding
    {' '}
    <CheckIconStyled />
    {' '}
    icons and the search term itself will not be clickable.
  </HintTitleWrapStyled>
)

export default HintTitle
