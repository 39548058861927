import styled from 'styled-components'
import React, { FC, ReactElement } from 'react'

const ContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.blue.light};
  color: ${({ theme }) => theme.colors.black};
  padding: 8px 12px;
  border-radius: 8px;
  display: grid;
  grid-row-gap: 8px;
`

const ItemContainerStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: max-content;
  grid-column-gap: 8px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  line-height: 16px;
`

const NameStyled = styled.span`
  font-weight: 500;
`

export type TComparisonDetailsRow = {
  icon: ReactElement
  name: string
  items: string[] | ReactElement
}

const isStringArray = (items: TComparisonDetailsRow['items']): items is string[] => 'length' in items

const ComparisonDetails: FC<{ rows: TComparisonDetailsRow[] }> = ({ rows }) => (
  <ContainerStyled>
    {rows.map(({ icon, name, items }) => (
      <ItemContainerStyled key={name}>
        {icon}
        <span>
          <NameStyled>{`${name}: `}</NameStyled>
          {isStringArray(items) ? items.join(', ') : items}
        </span>
      </ItemContainerStyled>
    ))}
  </ContainerStyled>
)

export default ComparisonDetails
