import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material'
import styled from 'styled-components'
import { FC } from 'react'

const TooltipStyled = styled<FC<TooltipProps & { $maxWidth?: number }>>((props) => (
  <MuiTooltip classes={{ popper: props.className }} {...props} />
))`
  && .MuiTooltip-tooltip {
    color: ${({ theme }) => theme.colors.black};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    max-width:${({ $maxWidth }) => ($maxWidth || 280)}px;
    filter: drop-shadow(0px 2px 2px #373B3E26);
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    white-space: pre-wrap;
  }

  && .MuiTooltip-tooltipPlacementTop {
    margin: 8px 0 !important;
  }

  && .MuiTooltip-arrow {
    border: none;
    color: ${({ theme }) => theme.colors.white};
  }
 
`

export type TTooltipProps = {
  maxWidth?: number
} & TooltipProps

const Tooltip: FC<TTooltipProps> = ({ maxWidth, ...rest }) => (
  <TooltipStyled {...rest} arrow $maxWidth={maxWidth} PopperProps={{ style: { zIndex: 99999999999999 } }} />
)

export default Tooltip
