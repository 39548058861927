import { useState } from 'react'
import constate from 'constate'
import { TComparedKeyword } from '../definitions'

const useKeywordsState = () => {
  const [keywords, setKeywords] = useState<string>('')
  const [submittedKeywords, setSubmittedKeywords] = useState<string>('')
  const [comparedKeywords, setComparedKeywords] = useState<TComparedKeyword[]>([])

  return {
    keywords,
    setKeywords,
    submittedKeywords,
    setSubmittedKeywords,
    comparedKeywords,
    setComparedKeywords
  }
}

export const [KeywordsStateProvider, useKeywordsStateContext] = constate(useKeywordsState)
