import constate from 'constate'
import { useState } from 'react'

const MAX_SELECTED_DATASETS = 3

const useDatasetSelect = () => {
  const [selectedIds, setSelectedId] = useState<string[]>([])

  return {
    selectDataset: (id: string) => {
      if (selectedIds.length >= MAX_SELECTED_DATASETS) {
        return
      }
      setSelectedId([...selectedIds, id])
    },
    isSelected: (id: string) => selectedIds.includes(id),
    removeDataset: (id: string) => setSelectedId(selectedIds.filter((selectedId) => selectedId !== id)),
    selectedIds,
    isMaxSelected: selectedIds.length >= MAX_SELECTED_DATASETS,
    clearSelectedIds: () => setSelectedId([])
  }
}

export const [DatasetSelectProvider, useDatasetSelectContext] = constate(useDatasetSelect)
