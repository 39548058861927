import { FC } from 'react'
import { DialogContent, DialogContentProps } from '@mui/material'

const ModalContent: FC<DialogContentProps> = ({ sx, ...rest }) => (
  <DialogContent
    {...rest}
    sx={{
      padding: '0 20px 24px',
      ...sx
    }}
  />
)

export default ModalContent
