import { useDatasetSelectContext } from '../state/useDatasetSelect'

export const useDatasetSelect = (datasetId: string) => {
  const {
    isSelected,
    selectDataset,
    removeDataset,
    isMaxSelected
  } = useDatasetSelectContext()

  const handleSelect = () => {
    if (isSelected(datasetId)) {
      removeDataset(datasetId)
      return
    }
    selectDataset(datasetId)
  }
  const isDatasetSelected = isSelected(datasetId)

  return {
    isSelected: isDatasetSelected,
    handleSelect,
    isDisabled: !isDatasetSelected && isMaxSelected
  }
}
