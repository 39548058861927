import { useResponseInterceptor } from '../hooks/useResponseInterceptor'
import { useRequestInterceptor } from '../hooks/useRequestInterceptor'

const ApiInterceptors = () => {
  useRequestInterceptor()
  useResponseInterceptor()

  return null
}

export default ApiInterceptors
