import {
  TComparedVariable,
  TDatasetsVariablesData,
  TDatasetVariablesData,
  TKeywordData,
  TValue,
  TVersion
} from '../../../definitions/variable'
import { TDatasetBaseData } from '../../../definitions/dataset'
import { TRowData } from './definitions'

export const TOGGLE_BUTTON_VAL_DESCRIPTION = 'toggleButtonValDescription'

const tableRowCollapseButton = {
  name: '',
  shortName: '',
  id: TOGGLE_BUTTON_VAL_DESCRIPTION as any,
  year: '',
  file: '',
  varCode: '',
  varDescription: '',
  valCode: '',
  valDescription: TOGGLE_BUTTON_VAL_DESCRIPTION,
  isMatch: true
}

export const checkIsMatch = (keywords: string[], value: string) =>
  keywords.some((keyword) => value?.trim().toLowerCase().includes(keyword.toLowerCase()))

export const prepareDataForSubmit = (comparedVariables: TComparedVariable[]): TKeywordData[] => {
  const keywordsData = comparedVariables.reduce(
    (acc, { keyword, variableId }) => ({
      ...acc,
      [keyword]: [...(acc[keyword] || []), variableId]
    }),
    {} as Record<string, number[]>
  )

  return Object.entries(keywordsData).map(([name, variables]) => ({
    name,
    variablesId: variables
  }))
}

const prioritizeValues = (keywordNames: string[], values: TValue[] | Omit<TValue, 'id'>[]) => {
  const keywordSet = new Set(keywordNames.map((keyword) => keyword.toLowerCase()))

  return values.sort((a, b) => {
    const aContainsKeyword = Array.from(keywordSet).some((keyword) => a.description.toLowerCase().includes(keyword))
    const bContainsKeyword = Array.from(keywordSet).some((keyword) => b.description.toLowerCase().includes(keyword))

    if (aContainsKeyword && !bContainsKeyword) return -1

    if (!aContainsKeyword && bContainsKeyword) return 1

    return 0
  })
}

const getRowsByVersions = (versions: TVersion[], keywordNames: string[]): TRowData[] =>
  versions?.flatMap((version) =>
    version.files?.flatMap((file) =>
      file.variables?.flatMap((variable) => {
        const values = variable.values?.length ? variable.values : [{ code: '-', description: '-' }]

        const sortedValues = prioritizeValues(keywordNames, values)

        return sortedValues?.flatMap((value, valueIndex) => ({
          year: version.year,
          file: file.name,
          varCode: variable.code,
          varDescription: variable.description,
          valCode: value.code || '-',
          valDescription: value.description || '-',
          expandable: sortedValues?.length > 1,
          showToggleButton: !valueIndex,
          isMatch: !valueIndex && !checkIsMatch(keywordNames, value.description) ? true : checkIsMatch(keywordNames, value.description)
        }))
      })))

export const prepareDatasetVariables = (
  variables: TDatasetVariablesData[],
  dataset: TDatasetBaseData,
  keywordNames: string[]
): TRowData[] => {
  const versions = variables?.flatMap(({ versions }) => versions)

  const preparedDateset = getRowsByVersions(versions, keywordNames) ?? []

  addButton(preparedDateset)

  const [firstRow, ...restRows] = preparedDateset

  if (!firstRow) {
    return []
  }

  return [
    {
      name: dataset.name,
      shortName: dataset.shortName,
      id: dataset.id,
      year: firstRow.year,
      file: firstRow.file,
      varCode: firstRow.varCode,
      varDescription: firstRow.varDescription,
      valCode: firstRow.valCode,
      valDescription: firstRow.valDescription,
      isMatch: firstRow?.isMatch,
      subRows: restRows
        ? restRows.map((data) => ({
          ...data,
          name: data.valDescription === TOGGLE_BUTTON_VAL_DESCRIPTION ? undefined : dataset.name,
          shortName: data.valDescription === TOGGLE_BUTTON_VAL_DESCRIPTION ? undefined : dataset.shortName
        }))
        : []
    }
  ]
}

function addButton(rows: TRowData[]) {
  let needAddButton = false

  for (let i = 0; i < rows.length; i++) {
    if (rows[i].showToggleButton) {
      if (needAddButton) {
        rows.splice(i, 0, {
          ...tableRowCollapseButton,
          id: `${tableRowCollapseButton.id}_${i}` as any
        })
        needAddButton = false
        i++
      }
    }

    if (rows[i].expandable) needAddButton = true
  }

  if (needAddButton) {
    rows.push(tableRowCollapseButton)
  }
}

export const prepareKeywordData = (variables: TDatasetsVariablesData[], keywordNames: string[]): TRowData[] =>
  variables?.flatMap(({ dataset_short_name, dataset_name, dataset_id, versions }) => {
    const preparedDateset = getRowsByVersions(versions, keywordNames) ?? []

    addButton(preparedDateset)

    const [firstRow, ...restRows] = preparedDateset

    if (!firstRow) {
      return []
    }

    return {
      name: dataset_name,
      shortName: dataset_short_name,
      id: dataset_id,
      year: firstRow?.year,
      file: firstRow?.file,
      varCode: firstRow?.varCode,
      varDescription: firstRow?.varDescription,
      valCode: firstRow?.valCode,
      valDescription: firstRow?.valDescription,
      isMatch: firstRow?.isMatch,
      subRows: restRows
        ? restRows.map((data) => ({
          ...data,
          name: data.valDescription === TOGGLE_BUTTON_VAL_DESCRIPTION ? undefined : dataset_name,
          shortName: data.valDescription === TOGGLE_BUTTON_VAL_DESCRIPTION ? undefined : dataset_short_name
        }))
        : []
    }
  })

export const getVariablesFromVersions = (versions: TVersion[]): string[] =>
  versions?.flatMap((version) => version?.files?.flatMap((file) => file?.variables?.map((variable) => variable.code)))

export const getDatasetNamesFromVariables = (variables: TDatasetsVariablesData[]): string[] =>
  variables?.map(({ dataset_name }) => dataset_name)
