import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { useFeedbackDownloadQuery } from '../queries'
import { useNotificationContext } from '../../notifications/state/useNotification'
import ErrorNotification from '../../notifications/components/ErrorNotification'

const ButtonStyled = styled.button`
    all: unset;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: ${({ theme }) => theme.colors.blue.primary};
    text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
    position: relative;
    cursor: pointer;
    &:before{
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
        background:${({ theme }) => theme.colors.blue.primary};
    }
`

type Props={
  id:number
}

const Attachments:FC<Props> = ({ id }) => {
  const { refetch, isError, error } = useFeedbackDownloadQuery(id)
  const { showErrorNotification } = useNotificationContext()
  const handleAttachments = () => {
    refetch()
  }

  useEffect(() => {
    if (isError) {
      showErrorNotification(<ErrorNotification message={error?.message ?? 'Something went wrong'} />)
    }
  }, [isError])

  return (
    <ButtonStyled onClick={handleAttachments}>Download</ButtonStyled>
  )
}

export default Attachments
