import { useQueryClient } from 'react-query'
import { useDeleteUserMutation } from '../queries/useDeleteUserMutation'
import { useUserContext } from '../state/useUser'
import { USERS_QUERY_KEY } from '../queries/useUsersQuery'

export const useDeleteUser = (onSuccess: () => void) => {
  const {
    mutateAsync,
    isLoading
  } = useDeleteUserMutation()
  const queryClient = useQueryClient()
  const { id } = useUserContext()

  const deleteUser = async () => {
    await mutateAsync(id)
    onSuccess()
    await queryClient.invalidateQueries(USERS_QUERY_KEY)
  }

  return {
    deleteUser,
    isLoading
  }
}
