import { useNavigate } from 'react-router-dom'
import { useRevokeTokenMutation } from '../queries/useRevokeTokenMutation'
import { useAuthContext } from '../../../../state/useAuth'
import { ROUTES } from '../../../../constants/routes'

export const useLogout = () => {
  const navigate = useNavigate()
  const { mutateAsync } = useRevokeTokenMutation()
  const { authData, removeAuthData } = useAuthContext()

  return async () => {
    if (!authData) return
    await mutateAsync(authData.refresh_token)
    removeAuthData()
    navigate(ROUTES.LOGIN)
  }
}
