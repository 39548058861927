import styled from 'styled-components'
import { ChangeEvent, FC } from 'react'
import { Control, useController } from 'react-hook-form'

const ContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const TitleStyled = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: ${({ theme }) => theme.colors.grey.dark};
`

const LabelStyled = styled.label`
    width: 100px;
    height: 36px;
    padding: 6px 16px 6px 16px;
    border-radius:4px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.grey.primary};
    cursor: pointer;
`

const InputStyled = styled.input`
    display: none;
`

type TProps = {
  name: string
  label: string
  accept: string
  title: string
  control: Control<any>
  clearError: () => void
  handleChange: (event: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => void
}
const FileUpload:FC<TProps> = ({
  name,
  label,
  accept,
  clearError,
  control,
  handleChange,
  title
}) => {
  const {
    field: { ref, onChange }
  } = useController({
    name,
    control
  })
  return (
    <ContainerStyled>
      <TitleStyled>{label}</TitleStyled>
      <LabelStyled htmlFor="file-upload">
        {title}
        <InputStyled
          name={name}
          ref={ref}
          onClick={clearError}
          id="file-upload"
          accept={accept}
          type="file"
          onChange={(event) => handleChange(event, onChange)}
        />
      </LabelStyled>
    </ContainerStyled>
  )
}

export default FileUpload
