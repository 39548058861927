import { useMemo } from 'react'
import { useFilteredUpdates } from './useFilteredUpdates'
import { useSearchContext } from '../state/useSearch'
import { usePaginationContext } from '../../../../hooks/usePagination'
import { prepareTableData } from '../utils'
import { sliceRows } from '../../../../utils/table'

export const useTableData = () => {
  const filteredUpdates = useFilteredUpdates()
  const { search } = useSearchContext()
  const { page, rowsPerPage } = usePaginationContext()

  return useMemo(
    () => prepareTableData(sliceRows(page, rowsPerPage, filteredUpdates)),
    [filteredUpdates, search, page]
  )
}
