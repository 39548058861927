import { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

const ContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.shadow};
`

type TProps = {
  className?: string
} & PropsWithChildren

const Card: FC<TProps> = ({ children, className }) => (
  <ContainerStyled className={className}>
    {children}
  </ContainerStyled>
)

export default Card
