import styled from 'styled-components'
import Button from '../../../shared/Button'
import CreateUserModal from './CreateUserModal'
import { useModal } from '../hooks/useModal'
import { useUsersQuery } from '../queries/useUsersQuery'

const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleStyled = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
`

const Header = () => {
  const { isFetching } = useUsersQuery(false)
  const [isOpen, closeModal, openModal] = useModal()

  return (
    <ContainerStyled>
      <TitleStyled>User Management</TitleStyled>
      <Button disabled={isFetching} onClick={openModal}>Create</Button>
      <CreateUserModal closeModal={closeModal} open={isOpen} />
    </ContainerStyled>
  )
}

export default Header
