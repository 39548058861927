import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { FC } from 'react'
import FormTextField from '../../../shared/formControls/FormTextField'
import { TUserForm } from '../definitions'
import FormSelect from '../../../shared/formControls/FormSelect'
import { EMAIL_REGEXP, PASSWORD_REGEXP } from '../regexp'
import { INVALID_EMAIL_MESSAGE, INVALID_PASSWORD_MESSAGE, REQUIRED_FIELD_MESSAGE } from '../constants'

const FormStyled = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px 16px;
`

type TProps = {
  isPasswordRequired?: boolean
}

const UserForm: FC<TProps> = ({ isPasswordRequired = true }) => {
  const { control } = useFormContext<TUserForm>()

  return (
    <FormStyled>
      <FormTextField
        name="firstName"
        control={control}
        fullWidth
        label="First Name"
        required
        rules={{ required: { value: true, message: REQUIRED_FIELD_MESSAGE } }}
      />
      <FormTextField
        name="lastName"
        control={control}
        fullWidth
        label="Last Name"
        required
        rules={{ required: { value: true, message: REQUIRED_FIELD_MESSAGE } }}
      />
      <FormTextField
        name="email"
        control={control}
        fullWidth
        label="Email"
        trim
        required
        rules={{
          required: { value: true, message: REQUIRED_FIELD_MESSAGE },
          pattern: { value: EMAIL_REGEXP, message: INVALID_EMAIL_MESSAGE }
        }}
      />
      <FormTextField
        name="organization"
        control={control}
        fullWidth
        label="Organization"
      />
      <FormTextField
        name="password"
        control={control}
        trim
        required={isPasswordRequired}
        fullWidth
        label="Password"
        rules={{
          required: { value: isPasswordRequired, message: REQUIRED_FIELD_MESSAGE },
          pattern: { value: PASSWORD_REGEXP, message: INVALID_PASSWORD_MESSAGE }
        }}
      />
      <FormSelect
        name="userRole"
        control={control}
        label="Role"
        required
        options={[
          {
            label: 'Admin',
            value: 'admin'
          },
          {
            label: 'User',
            value: 'user'
          }
        ]}
      />
    </FormStyled>
  )
}

export default UserForm
