import { Column } from 'react-table'
import Table from '../../../shared/table/components/Table'
import { useTableData } from '../hooks/useTableData'

const columns: Column[] = [
  {
    Header: 'Dataset Name',
    accessor: 'name'
  },
  {
    Header: 'Number of updated records',
    columns: [
      {
        Header: 'Dataset',
        accessor: 'numberOfRecords.dataset'
      },
      {
        Header: 'Dimension',
        accessor: 'numberOfRecords.dimension'
      },
      {
        Header: 'Variables',
        accessor: 'numberOfRecords.variables'
      }
    ]
  },
  {
    Header: 'Values',
    accessor: 'values'
  },
  {
    Header: 'Update date',
    accessor: 'updatedAt'
  }
]

const UpdatesTable = () => {
  const data = useTableData()

  return (
    <Table data={data} columns={columns} />
  )
}

export default UpdatesTable
