import { useState } from 'react'

export const useRowHeightByCell = () => {
  const [rowHeight, setRowHeight] = useState(0)

  const cellRefCallback = (e: HTMLTableRowElement) => {
    const cellHeight = e?.getBoundingClientRect().height ?? 0
    if (cellHeight > rowHeight) {
      setRowHeight(cellHeight)
    }
    if (cellHeight < rowHeight && e) {
      e.style.height = 'inherit'
    }
  }

  return {
    cellRefCallback,
    rowHeight
  }
}
