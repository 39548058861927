import styled from 'styled-components'
import Card from '../../../shared/Card'
import {
  ACCESS_RESTRICTION,
  ACCESSIBILITY_TEXT,
  AGREEMENT,
  ARBITRATION,
  AUTOMATICALLY_COLLECTED_INFORMATION,
  CHANGES_TO_TERMS,
  CHILDREN_UNDER_THIRTEEN,
  CONTACT_US,
  DISCLOSURE,
  ELECTRONIC_COMMUNICATIONS,
  INDEMNIFICATION,
  INTERNATIONAL_USERS,
  LIABILITY_DISCLAIMER,
  LINKS_TO_THIRD_PARTY,
  NO_UNLAWFUL,
  PERSONALLY_IDENTIFIABLE_INFORMATION,
  YOUR_ACCOUNT
} from '../constants'

const CardStyled = styled(Card)`
  padding: 20px;
  color: ${({ theme }) => theme.colors.black};
  max-width: 960px;
  margin: auto;
  white-space: pre-wrap;
`

const TitleStyled = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`

const TextStyled = styled.span`
  font-size: 14px;
  line-height: 16px;
`

const SubTitleStyled = styled.h2<{ $isInline?: boolean }>`
  line-height: 16px;
  font-weight: 500;
  font-size: 14px;
  display: ${({ $isInline }) => ($isInline ? 'inline' : 'block')};
`

const TermsOfUse = () => (
  <CardStyled>
    <TitleStyled>Accessibility</TitleStyled>
    {'\n'}

    <TextStyled>{ACCESSIBILITY_TEXT}</TextStyled>
    {'\n\n'}

    <TitleStyled>Privacy Policy</TitleStyled>
    {'\n'}

    <SubTitleStyled $isInline>Personally identifiable information.</SubTitleStyled>
    {' '}
    <TextStyled>{PERSONALLY_IDENTIFIABLE_INFORMATION}</TextStyled>
    {'\n\n'}

    <SubTitleStyled $isInline>Automatically collected information.</SubTitleStyled>
    {' '}
    <TextStyled>{AUTOMATICALLY_COLLECTED_INFORMATION}</TextStyled>
    {'\n\n'}

    <SubTitleStyled $isInline>Disclosure.</SubTitleStyled>
    {' '}
    <TextStyled>{DISCLOSURE}</TextStyled>
    {'\n\n'}

    <TitleStyled>Terms and Conditions</TitleStyled>
    {'\n'}

    <SubTitleStyled>Agreement between User and healthdataselect.com</SubTitleStyled>
    <TextStyled>{AGREEMENT}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Electronic Communications</SubTitleStyled>
    <TextStyled>{ELECTRONIC_COMMUNICATIONS}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Your Account</SubTitleStyled>
    <TextStyled>{YOUR_ACCOUNT}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Children Under Thirteen</SubTitleStyled>
    <TextStyled>{CHILDREN_UNDER_THIRTEEN}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Links to Third Party Sites/Third Party Services</SubTitleStyled>
    <TextStyled>{LINKS_TO_THIRD_PARTY}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>No Unlawful or Prohibited Use/Intellectual Property</SubTitleStyled>
    <TextStyled>{NO_UNLAWFUL}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>International Users</SubTitleStyled>
    <TextStyled>{INTERNATIONAL_USERS}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Indemnification</SubTitleStyled>
    <TextStyled>{INDEMNIFICATION}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Arbitration</SubTitleStyled>
    <TextStyled>{ARBITRATION}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Liability Disclaimer</SubTitleStyled>
    <TextStyled>{LIABILITY_DISCLAIMER}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Termination/Access Restriction</SubTitleStyled>
    <TextStyled>{ACCESS_RESTRICTION}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Changes to Terms</SubTitleStyled>
    <TextStyled>{CHANGES_TO_TERMS}</TextStyled>
    {'\n\n'}

    <SubTitleStyled>Contact Us</SubTitleStyled>
    <TextStyled>{CONTACT_US}</TextStyled>
  </CardStyled>
)

export default TermsOfUse
