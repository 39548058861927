import { useMutation } from 'react-query'
import { apiClient } from '../../../../api/apiClient'

export const REVOKE_TOKEN_API_URL = '/auth/revoke-token'

const revokeToken = async (token: string) => await apiClient.post(REVOKE_TOKEN_API_URL, { token })

const REVOKE_TOKEN_MUTATION_KEY = 'revokeToken'

export const useRevokeTokenMutation = () => useMutation(
  REVOKE_TOKEN_MUTATION_KEY,
  revokeToken
)
