import React, { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseLineIcon } from '../../../assets/CloseLine.svg'
import { ReactComponent as FileIcon } from '../../../assets/FileIcon.svg'

const WrapperStyled = styled.div`
    padding: 5px 8px;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.grey.dark};
    display: flex;
    align-items: center;
    width: max-content;
`

const LabelStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
`

const ButtonWrapStyled = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
`

type Props={
  label:string
  removeImage:()=>void
}
const ImageFile:FC<Props> = ({ label, removeImage }) => (
  <WrapperStyled>
    <FileIcon />
    <LabelStyled>{label}</LabelStyled>
    <ButtonWrapStyled
      onClick={removeImage}
      type="button"
    >
      <CloseLineIcon width={20} height={20} />
    </ButtonWrapStyled>
  </WrapperStyled>
)

export default ImageFile
