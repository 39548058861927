import { forwardRef } from 'react'
import { IconButtonProps, IconButton as MuiIconButton } from '@mui/material'
import { useTheme } from 'styled-components'

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ sx, ...rest }, ref) => {
  const { colors } = useTheme()

  return (
    <MuiIconButton
      {...rest}
      ref={ref}
      disableRipple
      disableFocusRipple
      sx={{
        padding: 0,
        fill: colors.grey.dark,
        '&:hover': {
          fill: colors.black
        },
        ...sx
      }}
    />
  )
})

export default IconButton
