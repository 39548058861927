import styled from 'styled-components'
import { ReactComponent as MessageIcon } from '../../../../assets/MessageIcon.svg'

const CardStyled = styled.div`
    padding: 12px 8px 12px 8px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.green500};
    background: ${(props) => props.theme.colors.green100};
    display: flex;
    flex-direction: column;
    max-width: 604px;
`

const IconWrapStyled = styled.div`
  align-self: center;
`

const ContentWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const TitleStyled = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.black};
`

const DescriptionStyled = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.black};
`
const UserInfoFeedback = () => (
  <CardStyled>
    <IconWrapStyled>
      <MessageIcon />
    </IconWrapStyled>
    <ContentWrapperStyled>
      <TitleStyled>Welcome, HDS beta user! Thank you for helping us make this tool better.</TitleStyled>
      <DescriptionStyled>
        We greatly appreciate any feedback (big or small). Just click the feedback button available on the top of each page.
      </DescriptionStyled>
      <DescriptionStyled>
        For more general impressions, look for our follow-up email survey. Thanks!
      </DescriptionStyled>
    </ContentWrapperStyled>
  </CardStyled>
)

export default UserInfoFeedback
