import { useMutation } from 'react-query'
import { TUserForm } from '../definitions'
import { apiClient } from '../../../../api/apiClient'

const editUser = async ({ formData, userId }: { formData: TUserForm, userId: string }) => apiClient.put(
  `/users/${userId}`,
  formData
)

export const EDIT_USER_MUTATION_KEY = 'editUser'

export const useEditUserMutation = () => useMutation(
  EDIT_USER_MUTATION_KEY,
  editUser
)
