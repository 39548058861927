import { useMemo } from 'react'
import { useDatasetsContext } from '../state/useDatasets'

export const useKeywordsByDatasets = (): string[] => {
  const { datasets } = useDatasetsContext()

  return useMemo(
    () => {
      if (!datasets) {
        return []
      }

      const keywords = datasets?.reduce(
        (acc, dataset) => {
          const datasetKeywords = dataset.keywords?.map(({ name }) => name) ?? []
          return ([...acc, ...datasetKeywords])
        },
        [] as string[]
      )

      // @ts-ignore
      return ([...new Set(keywords)])
    },
    [datasets]
  )
}
