import constate from 'constate'
import { useState } from 'react'
import { TRowData } from '../definitions'

const useTableRows = () => {
  const [tableRows, setTableRows] = useState<TRowData[] | undefined>()

  return {
    tableRows,
    setTableRows
  }
}

export const [TableRowsProvider, useTableRowsContext] = constate(useTableRows)
