import { DialogProps } from '@mui/material'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Modal from '../../../shared/modal/Modal'
import ModalTitle from '../../../shared/modal/ModalTitle'
import ModalContent from '../../../shared/modal/ModalContent'
import ModalActions from '../../../shared/modal/ModalActions'
import Button from '../../../shared/Button'
import UserForm from './UserForm'
import { TUserForm } from '../definitions'
import useEditUser from '../hooks/useEditUser'
import { useUserContext } from '../state/useUser'
import { setEmailValidationError } from '../utils'

type TProps = {
  closeModal: () => void
} & DialogProps

const EditUserModal: FC<TProps> = ({
  closeModal,
  open,
  ...rest
}) => {
  const user = useUserContext()
  const methods = useForm<TUserForm>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      organization: user.organization || '',
      password: '',
      userRole: user.userRole
    }
  })

  const { isLoading, editUser } = useEditUser(
    closeModal,
    (error) => setEmailValidationError(methods, error)
  )

  useEffect(() => {
    if (open) {
      methods.reset()
    }
  }, [open])

  return (
    <Modal open={open} closeModal={closeModal} withCloseButton {...rest}>
      <ModalTitle>
        Edit user
      </ModalTitle>
      <ModalContent>
        <FormProvider {...methods}>
          <UserForm isPasswordRequired={false} />
        </FormProvider>
      </ModalContent>
      <ModalActions>
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={methods.handleSubmit(editUser)}
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default EditUserModal
