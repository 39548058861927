import styled from 'styled-components'
import pluralize from 'pluralize'
import DatasetsTable from '../DatasetsTable'
import { useDatasetsContext } from '../../state/useDatasets'

const DatasetsContainerStyled = styled.div`
  margin-bottom: 20px;
`

const TitleStyled = styled.span`
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
`

const FilteredDatasets = () => {
  const { datasets } = useDatasetsContext()

  return (
    <DatasetsContainerStyled>
      <TitleStyled>
        {`${pluralize('Dataset', datasets?.length ?? 0, true)} found`}
      </TitleStyled>
      <DatasetsTable showHint />
    </DatasetsContainerStyled>
  )
}

export default FilteredDatasets
