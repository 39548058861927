import { useEffect } from 'react'
import { useDatasetsQuery } from '../queries/useDatasetsQuery'
import { useFiltersStateContext } from '../state/useFiltersState'
import { useKeywordsStateContext } from '../state/useKeywordsState'

export const useFetchDatasets = () => {
  const selectedFiltersCount = useFiltersStateContext().getSelectedFiltersCount()
  const { isFetching, isFetched, refetch } = useDatasetsQuery(false)
  const { submittedKeywords } = useKeywordsStateContext()

  useEffect(() => {
    if (!selectedFiltersCount && !submittedKeywords) {
      refetch()
    }
  }, [selectedFiltersCount, submittedKeywords])

  return { isFetching: isFetching || !isFetched }
}
