import React, { FC, useEffect, useState } from 'react'
import { ToggleButtonGroup } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import ToggleButton from '../../shared/ToggleButton'
import { ROUTES } from '../../../constants/routes'

type TView = 'adminPanel' | 'website'

const getViewByPathname = (pathname: string) => (
  pathname === ROUTES.ADMIN_PANEL
    ? 'adminPanel'
    : 'website'
)

const useActiveView = () => {
  const { pathname } = useLocation()
  const [view, setView] = useState<TView>(getViewByPathname(pathname))

  useEffect(() => {
    setView(getViewByPathname(pathname))
  }, [pathname])

  return view
}

const ViewToggle: FC<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate()
  const view = useActiveView()

  const handleChange = (event: React.MouseEvent<HTMLElement>, value: TView | null) => {
    if (!value) return
    navigate(value === 'adminPanel' ? ROUTES.ADMIN_PANEL : ROUTES.DATASETS)
  }

  return (
    <ToggleButtonGroup
      value={view}
      exclusive
      className={className}
      onChange={handleChange}
    >
      <ToggleButton value="adminPanel">
        Admin panel
      </ToggleButton>
      <ToggleButton
        sx={{
          margin: '0 !important',
          borderLeft: 'none !important'
        }}
        value="website"
      >
        Website
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default ViewToggle
