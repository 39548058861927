import { FC, PropsWithChildren } from 'react'
import { KeywordsStateProvider } from '../state/useKeywordsState'
import { FiltersStateProvider } from '../state/useFiltersState'
import { DatasetSelectProvider } from '../state/useDatasetSelect'

const DatasetsStateProvider: FC<PropsWithChildren> = ({ children }) => (
  <FiltersStateProvider>
    <DatasetSelectProvider>
      <KeywordsStateProvider>
        {children}
      </KeywordsStateProvider>
    </DatasetSelectProvider>
  </FiltersStateProvider>
)

export default DatasetsStateProvider
