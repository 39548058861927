import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Button from '../../../shared/Button'
import { ReactComponent as EmailIcon } from '../assets/Email.svg'
import { ReactComponent as LockIcon } from '../assets/Lock.svg'
import { ROUTES } from '../../../../constants/routes'
import { TForm } from '../definitions'
import FormTextField from '../../../shared/formControls/FormTextField'
import { INVALID_DATA_MESSAGE, REQUIRED_MESSAGE } from '../constants'
import { useLogin } from '../hooks/useLogin'
import { isInvalidCredentials } from '../utils'

const FormStyled = styled.form`
  display: grid;
  grid-row-gap: 16px;
`

const Form = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState,
    reset,
    clearErrors
  } = useForm<TForm>({
    defaultValues: {
      email: '',
      password: ''
    }
  })
  const navigate = useNavigate()
  const { errors, isDirty } = formState

  useEffect(() => {
    if (isDirty && (errors.email || errors.password)) {
      clearErrors()
    }
  }, [isDirty])

  const { login, isLoading } = useLogin(
    ({ userRole }) => {
      navigate(
        userRole === 'admin'
          ? ROUTES.ADMIN_PANEL
          : ROUTES.DATASETS
      )
    },
    (error) => {
      if (isInvalidCredentials(error)) {
        setError('password', { message: INVALID_DATA_MESSAGE })
        setError('email', { })
        reset(undefined, { keepValues: true, keepErrors: true })
      }
    }
  )

  return (
    <FormStyled onSubmit={handleSubmit(login)}>
      <FormTextField
        id="email"
        label="Email"
        fullWidth
        name="email"
        placeholder="email@domain.com"
        startIcon={<EmailIcon />}
        type="email"
        control={control}
        rules={{
          required: { value: true, message: REQUIRED_MESSAGE }
        }}
      />
      <FormTextField
        id="password"
        label="Password"
        fullWidth
        name="password"
        placeholder="********"
        type="password"
        startIcon={<LockIcon />}
        control={control}
        rules={{
          required: { value: true, message: REQUIRED_MESSAGE }
        }}
      />
      <Button type="submit" disabled={isLoading} loading={isLoading}>
        Log in
      </Button>
    </FormStyled>
  )
}

export default Form
