import { Pagination as MuiPagination, PaginationItem, PaginationProps } from '@mui/material'
import { FC } from 'react'
import { useTheme } from 'styled-components'
import { ReactComponent as FirstIcon } from './assets/First.svg'
import { ReactComponent as LastIcon } from './assets/Last.svg'
import { ReactComponent as PreviousIcon } from './assets/Previous.svg'
import { ReactComponent as NextIcon } from './assets/Next.svg'

const Pagination: FC<PaginationProps> = (props) => {
  const { colors } = useTheme()

  return (
    <MuiPagination
      {...props}
      showFirstButton
      showLastButton
      renderItem={(item) => (
        <PaginationItem
          slots={{
            last: LastIcon,
            first: FirstIcon,
            previous: PreviousIcon,
            next: NextIcon
          }}
          {...item}
        />
      )}
      sx={{
        '& .MuiPaginationItem-root': {
          color: colors.black,
          fill: colors.black,
          '&.Mui-disabled': {
            fill: colors.grey.dark
          },
          '&.Mui-selected': {
            color: colors.white,
            backgroundColor: colors.blue.primary,
            '&:hover': {
              backgroundColor: colors.blue.dark
            }
          }
        }
      }}
    />
  )
}

export default Pagination
