import { Column } from 'react-table'
import { FC, useMemo } from 'react'
import styled from 'styled-components'
import Checkbox from '../../../shared/Checkbox'
import StripedTable from '../../../shared/stripedTable/StripedTable'
import { prepareDatasetsToDisplay } from '../utils'
import { useDatasetSelect } from '../hooks/useDatasetSelect'
import NameCell from './NameCell'
import { useDatasetSelectContext } from '../state/useDatasetSelect'
import Hint from '../../../shared/hint/Hint'
import { COMPARE_DATASETS_HINT } from '../constants'
import { useDatasetsContext } from '../state/useDatasets'
import CompareButton from './CompareButton'

const CompareButtonWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  svg {
    position: absolute;
    right: 85px;
  }
`

const getColumns = (isHintVisible: boolean): Column<any>[] => ([
  {
    Header: 'Dataset Name',
    accessor: 'name',
    Cell: NameCell
  },
  {
    Header: () => {
      const { selectedIds } = useDatasetSelectContext()
      return (
        <CompareButtonWrapperStyled>
          {isHintVisible && (
            <Hint
              placement="top"
              maxWidth={320}
              title={COMPARE_DATASETS_HINT}
              withDefaultColor
            />
          )}
          <CompareButton disabled={!selectedIds.length} />
        </CompareButtonWrapperStyled>
      )
    },
    accessor: 'checkbox',
    Cell: ({ row }) => {
      const {
        isSelected,
        handleSelect,
        isDisabled
      } = useDatasetSelect(row.original.id)
      return (
        row.canExpand
          ? (
            <Checkbox
              checked={isSelected}
              disabled={isDisabled}
              onChange={handleSelect}
            />
          )
          : null
      )
    }
  }
])

const useTableData = () => {
  const { datasets } = useDatasetsContext()

  return useMemo(
    () => prepareDatasetsToDisplay(datasets ?? [], 'name'),
    [datasets]
  )
}

type TProps = {
  showHint?: boolean
}

const DatasetsTable: FC<TProps> = ({ showHint = false }) => {
  const data = useTableData()
  return (
    <StripedTable
      data={data}
      columns={getColumns(showHint)}
      sx={{
        maxWidth: '754px',
        '& .MuiTableCell-root:last-of-type': {
          textAlign: 'center',
          width: '10%'
        }
      }}
    />
  )
}

export default DatasetsTable
