import { useMutation, useQueryClient } from 'react-query'
import { isCancel } from 'axios'
import { apiClient } from '../../../../api/apiClient'
import { TDataset, TFilterDatasetsRequest, TKeywordData } from '../definitions'
import { DATASETS_QUERY_KEY } from './useDatasetsQuery'
import { useAuthContext } from '../../../../state/useAuth'

type TResponse = {
  dataset: TDataset
  keywords: TKeywordData[]
}[]

type TProps = { data: TFilterDatasetsRequest, signal?: AbortSignal, accessToken?:string }

const updateDatasets = async ({ data, signal, accessToken }: TProps): Promise<TResponse> => await apiClient
  .post('/datasets', data, {
    signal,
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    }
  })
  .then((response) => response?.data)

export const DATASETS_MUTATION_KEY = 'updateDatasets'

export const useDatasetsMutation = () => {
  const queryClient = useQueryClient()
  const accessToken = useAuthContext().authData?.access_token
  return useMutation(
    DATASETS_MUTATION_KEY,
    (props:any) => updateDatasets({ ...props, accessToken }),
    {
      onSuccess: (data) => {
        if (!data) return
        queryClient.setQueryData<TDataset[]>(
          DATASETS_QUERY_KEY,
          data.map(({ dataset, keywords }) => ({
            ...dataset,
            keywords
          }))
        )
      },
      onError: (error) => {
        if (!isCancel(error)) {
          queryClient.setQueryData<TDataset[]>(
            DATASETS_QUERY_KEY,
            []
          )
        }
      }
    }
  )
}
