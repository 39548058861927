import styled from 'styled-components'
import { FC, FormEvent } from 'react'
import Button from '../../../../shared/Button'
import Hint from '../../../../shared/hint/Hint'
import { SEARCH_HINT } from '../../constants'
import SearchInput from '../../../../shared/SearchInput'
import { useKeywordsStateContext } from '../../state/useKeywordsState'
import { trackKeywords } from '../../utils'

const TitleContainerStyled = styled.div`
  display: flex;
  align-items: center;
`

const TitleStyled = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black};
  margin-right: 4px;
`

const SubTitleStyled = styled.span`
  display: block;
  margin: 6px 0 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey.dark};
  line-height: 16px;
`

const SearchFieldContainerStyled = styled.form`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  align-items: center;
  max-width: 656px;
`

const SearchField: FC<{ className?: string }> = ({ className }) => {
  const { keywords, setKeywords, setSubmittedKeywords } = useKeywordsStateContext()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setSubmittedKeywords(keywords)
    trackKeywords(keywords)
  }

  const handleClear = () => {
    setKeywords('')
    setSubmittedKeywords('')
  }

  return (
    <div className={className}>
      <TitleContainerStyled>
        <TitleStyled>Search for variables</TitleStyled>
        <Hint
          withDefaultColor
          placement="top"
          title={SEARCH_HINT}
          maxWidth={514}
        />
      </TitleContainerStyled>
      <SubTitleStyled>
        Enter search terms(s) or phrase(s) in quotation marks
      </SubTitleStyled>
      <SearchFieldContainerStyled onSubmit={handleSubmit}>
        <SearchInput
          fullWidth
          onClear={handleClear}
          value={keywords}
          placeholder="For example “Health status” Smoke Income"
          onChange={(e) => setKeywords(e.target.value)}
        />
        <Button type="submit">
          Search
        </Button>
      </SearchFieldContainerStyled>
    </div>
  )
}

export default SearchField
