import { TableCell, TableRow } from '@mui/material'
import { FC, memo } from 'react'
import { HeaderGroup } from 'react-table'
import { useRowHeightByCell } from '../../../hooks/useRowHeightByCell'

const HeaderRow: FC<{ headerGroup: HeaderGroup }> = ({ headerGroup }) => {
  const {
    cellRefCallback,
    rowHeight
  } = useRowHeightByCell()
  return (
    <TableRow {...headerGroup.getHeaderGroupProps({ style: { height: rowHeight } })}>
      {headerGroup.headers.map((tableGroup) => (
        <TableCell ref={cellRefCallback} {...tableGroup.getHeaderProps()}>
          {tableGroup.render('Header')}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default memo(HeaderRow)
