import { LinearProgress, LinearProgressProps } from '@mui/material'
import { FC } from 'react'
import styled, { useTheme } from 'styled-components'

const ContainerStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 34px;
`

const CounterStyled = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  justify-self: center;
`

const Progress: FC<LinearProgressProps> = ({ value, ...rest }) => {
  const { colors } = useTheme()

  return (
    <ContainerStyled>
      <LinearProgress
        {...rest}
        variant="determinate"
        value={value}
        sx={{
          '& .MuiLinearProgress-bar': {
            backgroundColor: colors.blue.primary
          },
          '&.MuiLinearProgress-root': {
            backgroundColor: 'rgba(33, 150, 243, 0.2)'
          }
        }}
      />
      <CounterStyled>{`${value}%`}</CounterStyled>
    </ContainerStyled>
  )
}

export default Progress
