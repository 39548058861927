import { FC } from 'react'
import styled from 'styled-components'
import { To, useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from './assets/Arrow.svg'

const ContainerStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  cursor: pointer;
  width: max-content;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey.dark};
  fill: ${({ theme }) => theme.colors.grey.dark};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
`

type TProps = {
  link: To | number
  pageName?: string
  onClick?: () => void
}

const BackLink: FC<TProps> = ({ link, pageName, onClick }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(link as To)
    onClick?.()
  }

  return (
    <ContainerStyled onClick={handleClick}>
      <ArrowIcon />
      <span>{`Back${pageName ? ` to ${pageName}` : ''}`}</span>
    </ContainerStyled>
  )
}

export default BackLink
