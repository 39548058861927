import { useEffect, useState } from 'react'
import constate from 'constate'
import { useQueryClient } from 'react-query'
import axios from 'axios'
import { useParsingStatusQuery } from '../../../../queries/useParsingStatusQuery'
import { UPDATES_QUERY_KEY, useUpdatesQuery } from '../queries/useUpdatesQuery'
import { isParsingStatusUnavailable } from '../utils'
import { TDatasetUpdate } from '../definitions'
import { useImportModalContext } from './useImportModal'
import { useClearStatusMutation } from '../queries/useClearStatusMutation'

const useSaveParsingData = (fileName?: string, importingProgress?: number) => {
  const { setFileName, setProgress } = useImportModalContext()

  useEffect(() => {
    if (fileName && importingProgress) {
      setFileName(fileName)
      setProgress(importingProgress)
    }
  }, [fileName, importingProgress])
}

const useUpdates = () => {
  const [isParsing, setIsParsing] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(false)
  const queryClient = useQueryClient()
  const { mutate: clearStatus } = useClearStatusMutation()
  const { data, error } = useParsingStatusQuery(isParsing)
  const { status: parsingStatus, fileName, importingProgress } = data || {}
  const { refetch, isFetched } = useUpdatesQuery()
  useSaveParsingData(fileName, importingProgress)

  useEffect(() => {
    if ((parsingStatus && parsingStatus !== 'IN_PROCESS') || axios.isAxiosError(error)) {
      setIsParsing(false)
      refetch().then(() => {
        setIsFetching(false)
      })
    }
    if (parsingStatus === 'FILE_UPLOADING') {
      clearStatus()
      return
    }
    if (parsingStatus === 'ERROR' || (axios.isAxiosError(error) && !isParsingStatusUnavailable(error))) {
      setIsError(true)
    }
  }, [parsingStatus, error])

  return {
    fetchUpdates: () => {
      setIsParsing(true)
      setIsFetching(true)
      setIsError(false)
    },
    isFetching: isFetching || (!isError && !isFetched),
    parsingStatus,
    isParsing,
    isError,
    updates: queryClient.getQueryData<TDatasetUpdate[]>(UPDATES_QUERY_KEY)
  }
}

export const [UpdatesProvider, useUpdatesContext] = constate(useUpdates)
