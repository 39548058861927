import { useEffect } from 'react'
import { useAuthContext } from '../../../../state/useAuth'
import { apiClient } from '../../../../api/apiClient'
import { setAuthorizationHeader } from '../utils'

export const useRequestInterceptor = () => {
  const accessToken = useAuthContext().authData?.access_token

  useEffect(
    () => {
      const interceptor = apiClient.interceptors.request.use(
        (config) => {
          if (config.headers && accessToken) {
            setAuthorizationHeader(config, accessToken)
          }
          return config
        },
        (error) => Promise.reject(error)
      )
      return () => apiClient.interceptors.request.eject(interceptor)
    },
    [accessToken]
  )
}
