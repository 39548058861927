import constate from 'constate'
import { useState } from 'react'
import { TSection } from '../components/features/adminPanel/definitions'

const useNavState = () => {
  const [activeSection, setActiveSection] = useState<TSection>('dataManagement')
  return {
    activeSection,
    setActiveSection
  }
}

export const [NavProvider, useNavContext] = constate(useNavState)
