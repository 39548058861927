import { FC } from 'react'
import { ButtonProps } from '@mui/material'
import Button from '../../shared/Button'

export type TCompareButtonProps = {
  text?: string
} & ButtonProps

export const CompareButton: FC<TCompareButtonProps> = ({ text, sx, ...rest }) => (
  <Button
    {...rest}
    variant="secondary"
    sx={{
      padding: '4px 10px',
      fontSize: '13px',
      lineHeight: '22px',
      height: '30px',
      width: 'max-content',
      ...sx
    }}
  >
    Compare
    {text ? ` ${text}` : ''}
  </Button>
)
