import {
  TEventName, TEventProperty, TUserProperty, TWindowWithGA
} from './definitions'

const initGoogleAnalytic = (id: string) => {
  const initScript = document.createElement('script')
  const loadAnalyticScript = document.createElement('script')

  loadAnalyticScript.async = true
  loadAnalyticScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`

  initScript.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${id}');
  `

  document.head.appendChild(loadAnalyticScript)
  document.head.appendChild(initScript)
}

const trackEvent = (name: TEventName, data?: Partial<Record<TEventProperty, any>>) => {
  (window as TWindowWithGA).gtag?.('event', name, data)
}

export const setUserId = (id: string) => {
  (window as TWindowWithGA).gtag?.('set', { user_id: id })
}

export const setUserProperty = (data?: Partial<Record<TUserProperty, any>>) => {
  (window as TWindowWithGA).gtag?.('set', 'user_properties', data)
}

export const getDataLayer = () => (window as TWindowWithGA).dataLayer

export default {
  initGoogleAnalytic,
  trackEvent,
  setUserId,
  getDataLayer,
  setUserProperty
}
