import { useMemo } from 'react'
import { useUsersQuery } from '../queries/useUsersQuery'

export const useTableData = () => {
  const users = useUsersQuery(false)?.data?.content

  return useMemo(
    () => users ?? [],
    [users]
  )
}
