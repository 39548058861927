import { FC } from 'react'
import { DialogProps } from '@mui/material'
import { useTheme } from 'styled-components'
import Modal from '../../../shared/modal/Modal'
import Button from '../../../shared/Button'
import ModalTitle from '../../../shared/modal/ModalTitle'
import ModalActions from '../../../shared/modal/ModalActions'
import ModalContent from '../../../shared/modal/ModalContent'
import { useUserContext } from '../state/useUser'
import { useDeleteUser } from '../hooks/useDeleteUser'

type TProps = {
  closeModal: () => void
} & DialogProps

const DeleteUserModal: FC<TProps> = ({
  closeModal,
  ...props
}) => {
  const { colors } = useTheme()
  const { firstName, lastName } = useUserContext()
  const { deleteUser, isLoading } = useDeleteUser(closeModal)

  return (
    <Modal closeModal={closeModal} withCloseButton {...props}>
      <ModalTitle>Delete user</ModalTitle>
      <ModalContent
        sx={{
          color: colors.black,
          fontSize: '14px'
        }}
      >
        Are you sure you want to delete the user
        {' '}
        <b>{`${firstName} ${lastName}`}</b>
        ?
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button
          variant="critical"
          onClick={deleteUser}
          disabled={isLoading}
          loading={isLoading}
        >
          Delete
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default DeleteUserModal
