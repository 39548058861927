import styled from 'styled-components'
import NavMenu from './NavMenu'
import DataManagement from '../../dataManagement/components/DataManagement'
import UserManagement from '../../userManagement/components/UserManagement'
import FeedbackManagement from '../../feedbackManagement/components/FeedbackManagement'
import { useNavContext } from '../../../../state/useNavState'

const ContainerStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 184px 1fr;
  grid-column-gap: 20px;
`

const NavMenuStyled = styled(NavMenu)`
  height: min-content;
`

const AdminPanel = () => {
  const { activeSection, setActiveSection } = useNavContext()
  return (
    <ContainerStyled>
      <NavMenuStyled activeSection={activeSection} onChange={setActiveSection} />
      { activeSection === 'dataManagement' && <DataManagement /> }
      { activeSection === 'userManagement' && <UserManagement /> }
      { activeSection === 'feedback' && <FeedbackManagement /> }
    </ContainerStyled>
  )
}

export default AdminPanel
