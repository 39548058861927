import { useEffect, useRef } from 'react'

export const useOnUpdate = (callback: () => void, deps: any[]) => {
  const didMount = useRef(false)

  useEffect(
    () => {
      if (didMount.current) {
        callback()
        return
      }

      didMount.current = true
    },
    deps
  )
}
