import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled, { DefaultTheme } from 'styled-components'
import NotificationIcon from './NotificationIcon'

type TToastContainer = {
  bgColor?: keyof DefaultTheme['colors'],
  textColor?: keyof DefaultTheme['colors']
}

const CustomToastContainer = styled(ToastContainer)<TToastContainer>`
  && {
      
    && .Toastify__toast {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 425px;
        min-height: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        border: 1px solid #F32121;
        padding: 2px 14px 2px 16px;
        right: 45%;
        color: black;
        background: black;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }

    && .Toastify__toast-body {
      margin: 0;
      padding: 0;
    }

    && .Toastify__toast-icon {
      display: none;
      padding: 0;
    }

    && .Toastify__close-button {
      opacity: 1;
      font-weight: 400;
    }
  }
`

const Notification = () => (
  <CustomToastContainer
    newestOnTop
    position="top-center"
    closeButton={<NotificationIcon />}
  />
)

export default Notification
