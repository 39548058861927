import { useQuery } from 'react-query'
import qs from 'qs'
import { apiClient } from '../../../../api/apiClient'
import { TDataset } from '../../../../definitions/dataset'

const getDatasets = async (ids: string[]): Promise<TDataset[]> =>
  await apiClient
    .get('/datasets/compare', {
      params: ids,
      paramsSerializer: (params) => qs.stringify({ datasetsId: params }, { arrayFormat: 'repeat' })
    })
    .then((response) => response?.data)

const DATASETS_QUERY_KEY = 'datasets'

export const useDatasetsQuery = (ids: string[]) =>
  useQuery(DATASETS_QUERY_KEY, () => getDatasets(ids), {
    cacheTime: 0
  })
