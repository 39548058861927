import { useEffect } from 'react'
import { useFilteredUpdates } from './useFilteredUpdates'
import { usePaginationContext } from '../../../../hooks/usePagination'
import { getPagesCount } from '../../../../utils/table'

export const usePaginationInit = () => {
  const filteredUpdates = useFilteredUpdates()
  const { setPagesCount, rowsPerPage } = usePaginationContext()

  useEffect(() => {
    setPagesCount(getPagesCount(filteredUpdates.length, rowsPerPage))
  }, [filteredUpdates])
}
