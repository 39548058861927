import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'

const ContainerStyled = styled.div`
  box-shadow: 0 0 4px 0 rgba(55, 59, 62, 0.1);
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 40px;
`

const ContentWrapperStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: 1fr;
  padding: 0 20px;
  font-size: 14px;
  line-height: 16px;
  height: 100%;
  max-width: ${({ theme }) => theme.maxContentWidth};
  margin: auto;
`

const LinksWrapperStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 24px;
  justify-self: center;
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.grey.dark};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

const EmailLinkStyled = styled(LinkStyled)`
  justify-self: end;
`

const TextStyled = styled.p`
  color: ${({ theme }) => theme.colors.black};
`

const Footer = () => {
  const navigate = useNavigate()
  return (
    <ContainerStyled>
      <ContentWrapperStyled>
        <TextStyled>© 2023 Stern Consulting LLC</TextStyled>
        <LinksWrapperStyled>
          <LinkStyled target="_blank" href="https://sternconsulting.com/">Stern Consulting LLC</LinkStyled>
          <LinkStyled onClick={() => navigate(ROUTES.ABOUT)}>About</LinkStyled>
          <LinkStyled onClick={() => navigate(ROUTES.TERMS_OF_USE)}>Terms of Use</LinkStyled>
        </LinksWrapperStyled>
        <EmailLinkStyled href="mailto:HDS.help@sternconsulting.com">HDS.help@sternconsulting.com</EmailLinkStyled>
      </ContentWrapperStyled>
    </ContainerStyled>
  )
}

export default Footer
