import { useTheme } from 'styled-components'
import {
  Table as MuiTable, TableBody, TableCell, TableRow, Theme
} from '@mui/material'
import { FC } from 'react'
import { SxProps } from '@mui/system'
import { TRow } from '../definitions'

type TProps = {
  data: any[],
  rows: TRow[],
  sx?: SxProps<Theme>
}

const DatasetDetailsTable:FC<TProps> = ({ data, rows, sx }) => {
  const { colors } = useTheme()

  return (
    <MuiTable
      sx={{
        tableLayout: 'fixed',
        '& .MuiTableCell-root': {
          color: colors.black,
          whiteSpace: 'pre-wrap',
          fontSize: '14px',
          lineHeight: '16px',
          border: 'none',
          padding: '10px',
          borderBottom: `1px solid ${colors.grey.primary}`,
          borderLeft: `1px solid ${colors.grey.primary}`,
          verticalAlign: 'top',
          '&:first-of-type': {
            borderLeft: 'none'
          }
        },
        ...sx
      }}
    >
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.accessor}>
            <TableCell
              sx={{
                '&.MuiTableCell-root': {
                  width: '175px',
                  fontWeight: 500,
                  paddingLeft: '0px !important'
                }
              }}
            >
              {row.Header}
            </TableCell>
            {data.map((item, index) => (
              <TableCell key={index}>
                {row.Cell?.(item) ?? item[row.accessor] ?? ''}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>

    </MuiTable>
  )
}

export default DatasetDetailsTable
