import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import { DATASET_QUERY_KEY } from '../queries/useDatasetQuery'
import { TDataset } from '../../../../definitions/dataset'

const TitleStyled = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
`

const TextStyled = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  line-height: 18px;
  white-space: pre-wrap;
`

const Overview = () => {
  const text = useQueryClient().getQueryData<TDataset>(DATASET_QUERY_KEY)?.overview

  return (
    <div>
      <TitleStyled>Overview</TitleStyled>
      <TextStyled>{text ?? ''}</TextStyled>
    </div>
  )
}

export default Overview
