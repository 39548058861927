import React from 'react'
import styled from 'styled-components'
import { ReactComponent as SearchIcon } from '../assets/Search.svg'
import { ReactComponent as ExploreIcon } from '../assets/Explore.svg'

const ContainerStyled = styled.div`
  display: grid;
  grid-row-gap: 8px;
  color: ${({ theme }) => theme.colors.black};
`

const TitleStyled = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`

const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  
  svg {
    margin-right: 8px;
  }
`

type TOpportunity = {
  icon: React.ReactElement,
  text: string
}

const items: TOpportunity[] = [
  {
    icon: <ExploreIcon />,
    text: 'Find and explore datasets'
  },
  {
    icon: <SearchIcon />,
    text: 'Search for specific variables'
  }
]

const Opportunities = () => (
  <ContainerStyled>
    <TitleStyled>With Health Data Select you can:</TitleStyled>
    {items.map(({ icon, text }) => (
      <ItemStyled key={text}>
        { icon }
        <span>{text}</span>
      </ItemStyled>
    ))}
  </ContainerStyled>
)

export default Opportunities
