import axios from 'axios'
import { format } from 'date-fns'
import { TDatasetUpdate, TRowData, TUpdate } from './definitions'

export const isParsingStatusUnavailable = (error: unknown) => axios.isAxiosError(error) && error.response?.status === 404

const formatDate = (date: string): string => format(new Date(date), 'yyyy-MM-dd hh:mmaaa')

const prepareSubRows = (updates: TDatasetUpdate['updates']): TRowData[] => (
  updates.map(
    ({ year, file_updates }) => ({
      name: year,
      subRows: file_updates.map((update) => ({
        name: update.name,
        updatedAt: formatDate(update.updated_date),
        values: update.updated_val,
        numberOfRecords: {
          variables: update.updated_var
        }
      }))
    })
  )
)

export const prepareTableData = (datasets: TDatasetUpdate[]): TRowData[] => (
  datasets.map((dataset) => ({
    name: `${dataset.name} - ${dataset.short_name}`,
    numberOfRecords: {
      dataset: 1,
      dimension: dataset.dimension_value,
      variables: dataset.variables
    },
    values: dataset.values,
    updatedAt: formatDate(dataset.updateAt),
    subRows: prepareSubRows(dataset.updates)
  }))
)

const findFile = (fileName: string, updates: TUpdate[]) => updates.find(
  (update) => !!update.file_updates.find(
    (file) => file.name.toLowerCase().includes(fileName)
  )
)

export const filterUpdates = (filterValue: string, data: TDatasetUpdate[]) => data.filter(
  (dataset) => (
    dataset.name.toLowerCase().includes(filterValue)
      || findFile(filterValue, dataset.updates)
  )
)

export const getChunks = (file: File) => {
  const chunkSize = 5 * 1024 * 1024 // 5 MB chunk size
  const chunks = []
  const fileSize = file.size
  let start = 0
  let end = chunkSize

  while (start < fileSize) {
    chunks.push(file.slice(start, end))
    start = end
    end = start + chunkSize
  }

  return chunks
}

export const getFormDataByChunk = (chunk: Blob, number: number, total: number, fileName: string) => {
  const formData = new FormData()
  formData.append('file', chunk, fileName)
  formData.append('numChunk', number.toString())
  formData.append('totalSize', total.toString())
  return formData
}

export const getProgressByChunk = (currentChunk: number, chunksCount: number): number => {
  const MAX_PROGRESS = 19 // The maximum percentage for the file upload stage
  return Math.round((currentChunk / chunksCount) * MAX_PROGRESS)
}
