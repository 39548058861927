import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import { DATASET_QUERY_KEY } from '../queries/useDatasetQuery'
import { TDataset } from '../../../../definitions/dataset'

const WrapperStyled = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const TitleStyled = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
`

const ContainerStyled = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-auto-rows: max-content;
`

const LabelStyled = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`

const LinkContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const LinkStyled = styled.a`
  && {
    text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
    color: ${({ theme }) => theme.colors.blue.primary};
    cursor: pointer;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
    background: transparent;

    &:hover {
      text-decoration: underline;
      background: transparent;
      text-decoration-color: ${({ theme }) => theme.colors.blue.dark};
      color: ${({ theme }) => theme.colors.blue.dark};
    }
  }
`

type TLink = {
  label: string
  link: string
}

const useLinks = (): TLink[] => {
  const dataset = useQueryClient().getQueryData<TDataset>(DATASET_QUERY_KEY)

  return [
    {
      label: 'Homepage:',
      link: dataset?.link_home ?? ''
    },
    {
      label: 'Documentation:',
      link: dataset?.link_documentation ?? ''
    },
    {
      label: 'Get data:',
      link: dataset?.link_data ?? ''
    }
  ]
}

const Links = () => {
  const links = useLinks()

  return (
    <WrapperStyled>
      <TitleStyled>Links</TitleStyled>
      <ContainerStyled>
        {links.map(({ label, link }) => (
          <LinkContainerStyled key={label}>
            <LabelStyled>{label}</LabelStyled>
            <LinkStyled target="_blank" href={link}>
              {link}
            </LinkStyled>
          </LinkContainerStyled>
        ))}
      </ContainerStyled>
    </WrapperStyled>
  )
}

export default Links
