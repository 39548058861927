import { useQuery } from 'react-query'
import { apiClient } from '../api/apiClient'
import { TDatasetVariablesData, TKeywordData } from '../definitions/variable'

const getCompareVariablesData = async (
  data: TKeywordData[],
  withValues: boolean,
  start?:number,
  count?:number
): Promise<TDatasetVariablesData[]> => (
  await apiClient
    .post('/keywords/compare-variables', data, { params: { values: withValues, start, count } })
    .then((response) => response?.data)
)

export const COMPARE_VARIABLES_QUERY_KEY = 'compareVariables'

export const useCompareVariablesQuery = (
  data: TKeywordData[],
  // eslint-disable-next-line default-param-last
  withValues = false,
  // eslint-disable-next-line default-param-last
  enabled = true,
  start?:number,
  count?:number,
  page?:number

) => (
  useQuery(
    [COMPARE_VARIABLES_QUERY_KEY, count, page],
    () => getCompareVariablesData(data, withValues, start, count),
    {
      cacheTime: 0,
      enabled,
      keepPreviousData: true
    }
  )
)
