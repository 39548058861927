import { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useTheme } from 'styled-components'

type TProps = {
  isActive: boolean
} & ButtonProps

const MenuButton: FC<TProps> = ({ isActive, ...rest }) => {
  const { colors } = useTheme()

  return (
    <Button
      {...rest}
      sx={{
        backgroundColor: isActive ? colors.blue.primary : colors.grey.light,
        color: isActive ? colors.white : colors.black,
        textTransform: 'none',
        minHeight: '32px',
        justifyContent: 'start',
        padding: '8px',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        fill: isActive ? colors.white : colors.grey.dark,
        border: `1px solid ${isActive ? colors.blue.primary : colors.grey.primary}`,
        textAlign: 'left',
        '& .MuiButton-startIcon': {
          marginLeft: '0px'
        },
        '& .MuiButton-endIcon': {
          marginRight: '0px',
          marginLeft: 'auto'
        },
        '&:hover': {
          backgroundColor: isActive ? colors.blue.dark : colors.grey.primary
        }
      }}
    />
  )
}

export default MenuButton
