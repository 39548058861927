import { FC } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Card from '../../../shared/Card'
import BackLink from '../../backLink/BackLink'
import Table from './Table'
import Links from './Links'
import Overview from './Overview'
import Spinner from '../../../shared/Spinner'
import { useFetchDataset } from '../hooks/useFetchDataset'
import { useBackLink } from '../hooks/useBackLink'
import { ROUTES } from '../../../../constants/routes'
import IconButton from '../../../shared/IconButton'
import { ReactComponent as EditIcon } from '../../../../assets/Edit.svg'

const CardStyled = styled(Card)`
  padding: 20px;
  max-width: 1136px;
  margin: auto;
`

const TitleStyled = styled.span`
  display: block;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 22px;
  margin: 12px 0 16px;
`

const TableDescriptionStyled = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  margin: 20px 0 10px 0;
`

const SpinnerStyled = styled(Spinner)`
  margin-top: 20px;
`

const NavigationWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const DatasetDetails: FC = () => {
  const { isFetching, data } = useFetchDataset()
  const { link, name } = useBackLink()
  const navigate = useNavigate()

  const content = (() => (
    <>
      <TitleStyled>
        {data?.name} ({data?.short_name})
      </TitleStyled>
      <Overview />
      <TableDescriptionStyled>Dataset Characteristics</TableDescriptionStyled>
      <Table />
      <Links />
    </>
  ))()

  return (
    <CardStyled>
      <NavigationWrapStyled>
        <BackLink link={link} pageName={name} />
        <IconButton
          onClick={() => navigate(ROUTES.DATASETS)}
          sx={{
            display: 'flex',
            gap: '8px',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '18.75px'
          }}
        >
          <EditIcon />
          Edit search
        </IconButton>
      </NavigationWrapStyled>

      {isFetching ? <SpinnerStyled /> : content}
    </CardStyled>
  )
}

export default DatasetDetails
