import { FC } from 'react'
import { Dialog, DialogProps } from '@mui/material'
import { useTheme } from 'styled-components'
import IconButton from '../IconButton'
import { ReactComponent as CrossIcon } from '../../../assets/Cross.svg'

export type TModelProps = {
  withCloseButton?: boolean,
  closeModal?: () => void
  width?:number
  maxHeight?:string
  rootClass?:string
} & DialogProps

const Modal: FC<TModelProps> = ({
  children,
  withCloseButton,
  closeModal,
  width,
  sx,
  maxHeight,
  rootClass,
  ...rest
}) => {
  const { colors } = useTheme()
  return (
    <Dialog
      {...rest}
      classes={{
        root: rootClass
      }}
      transitionDuration={0}
      style={{
        zIndex: 99999999999999
      }}
      sx={{
        '& .MuiPaper-root': {
          maxHeight: maxHeight ? `${maxHeight}` : '500px',
          width: `${width || 576}px`,
          borderRadius: '8px',
          boxShadow: `0px 2px 4px 0px ${colors.shadow}`
        },
        ...sx
      }}
    >
      {children}
      {withCloseButton && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 21,
            top: 21
          }}
          onClick={closeModal}
        >
          <CrossIcon />
        </IconButton>
      )}
    </Dialog>
  )
}

export default Modal
