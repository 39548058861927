import { useQuery } from 'react-query'
import { TDatasetUpdate } from '../definitions'
import { apiClient } from '../../../../api/apiClient'

const getUpdates = async (): Promise<TDatasetUpdate[]> => await apiClient
  .get('/datasets/management')
  .then((response) => response?.data)

export const UPDATES_QUERY_KEY = 'getUpdates'

export const useUpdatesQuery = () =>
  useQuery(UPDATES_QUERY_KEY, getUpdates, {
    enabled: false,
    cacheTime: 0
  })
