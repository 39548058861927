import { TForm } from '../definitions'
import { useLoginMutation } from '../queries/useLoginMutation'
import { useAuthContext } from '../../../../state/useAuth'
import { TAuthData } from '../../../../definitions/auth'
import googleAnalytic from '../../googleAnalytic/utils'

export const useLogin = (onSuccess: (authData: TAuthData) => void, onError: (error: unknown) => void) => {
  const { mutateAsync, isLoading } = useLoginMutation()
  const { setAuthData } = useAuthContext()

  const login = async (formData: TForm) => {
    try {
      const authData = await mutateAsync(formData)
      googleAnalytic.trackEvent('login')
      onSuccess(authData)
      setAuthData(authData)
    } catch (e) {
      onError(e)
    }
  }

  return {
    login,
    isLoading
  }
}
