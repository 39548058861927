import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  maxContentWidth: '1440px',
  colors: {
    blue: {
      primary: '#2196F3',
      light: '#E9F4FE',
      dark: '#187CCC'
    },
    green100: '#E5FFE6',
    green500: '#13B32C',
    black: '#373B3E',
    grey: {
      primary: '#E9E9E9',
      secondary: '#DBDBDB',
      dark: '#768A9A',
      light: '#F5F5F5'
    },
    red: {
      primary: '#F32121',
      dark: '#DF1515'
    },
    shadow: '#373B3E1A',
    white: '#FFFFFF'
  }
}
