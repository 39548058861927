import { FC } from 'react'
import { TextFieldProps } from '@mui/material'
import TextField from './TextField'
import Tooltip from './Tooltip'
import IconButton from './IconButton'
import { ReactComponent as SearchIcon } from '../../assets/Search.svg'
import { ReactComponent as CrossIcon } from '../../assets/Cross.svg'

type TProps = {
  onClear: () => void
} & TextFieldProps

const SearchInput: FC<TProps> = ({ onClear, value, ...rest }) => (
  <TextField
    value={value}
    startIcon={<SearchIcon />}
    endIcon={(
      !!value && (
        <Tooltip placement="top" title="Clear">
          <IconButton onClick={onClear}>
            <CrossIcon />
          </IconButton>
        </Tooltip>
      )
    )}
    {...rest}
  />
)
export default SearchInput
