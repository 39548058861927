import { FC } from 'react'
import { CellProps } from 'react-table'
import styled from 'styled-components'
import { useKeywordsStateContext } from '../../../state/useKeywordsState'
import { TKeywordData, TRowData } from '../../../definitions'
import { ROUTES } from '../../../../../../constants/routes'
import { ReactComponent as CheckIcon } from '../../../assets/Check.svg'
import { getParentRow } from '../../../../../../utils/table'
import Link from '../../../../../shared/Link'

const CheckIconStyled = styled(CheckIcon)`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  &:hover {
    fill: ${({ theme }) => theme.colors.blue.dark};
  }
`

const DatasetKeywordCell: FC<CellProps<TRowData> & { keyword: string }> = ({ row, rowsById, keyword }) => {
  const { setComparedKeywords } = useKeywordsStateContext()
  const keywordData = row.original.keywords?.find(({ name }) => name === keyword) as TKeywordData
  const parentRow = row.depth ? getParentRow(row, rowsById) : row

  const handleClick = () => {
    setComparedKeywords([{
      name: keyword,
      variablesId: keywordData.versions.flatMap(({ variablesId }) => variablesId),
      dataset: {
        id: parentRow.original.id as number,
        name: parentRow.original.name,
        shortName: parentRow.original.short_name as string
      }
    }])
  }

  return keywordData && keywordData.versions.length
    ? <Link to={ROUTES.COMPARE_VARIABLES}><CheckIconStyled onClick={handleClick} /></Link>
    : null
}

export default DatasetKeywordCell
