import { useEffect } from 'react'
import googleAnalytic, { getDataLayer } from '../utils'
import { useAuthContext } from '../../../../state/useAuth'

const useGoogleAnalytic = () => {
  useEffect(() => {
    const id = process.env.REACT_APP_GA_ID
    if (id) {
      googleAnalytic.initGoogleAnalytic(id)
    }
  }, [])
}

const useUserInit = () => {
  const { authData } = useAuthContext()

  useEffect(() => {
    const dataLayer = getDataLayer()
    if (dataLayer && authData) {
      googleAnalytic.setUserId(authData.id)
      googleAnalytic.setUserProperty({
        user_email: authData.email,
        first_name: authData.firstName,
        last_name: authData.lastName
      })
    }
  }, [authData?.id])
}

const GoogleAnalytic = () => {
  useGoogleAnalytic()
  useUserInit()

  return null
}

export default GoogleAnalytic
