import { createGlobalStyle } from 'styled-components'

const Styles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    overflow-y: scroll;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
  }
`

export default Styles
