import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Card from '../../../shared/Card'
import BackLink from '../../backLink/BackLink'
import { ROUTES } from '../../../../constants/routes'
import DatasetDetailsTable from '../../datasetDetailsTable/components/DatasetDetailsTable'
import { TRow } from '../../datasetDetailsTable/definitions'
import Link from '../../../shared/Link'
import Spinner from '../../../shared/Spinner'
import { useDatasetsIds } from '../hooks/useDatasetsIds'
import { useTableData } from '../hooks/useTableData'
import { useOpenDataset } from '../../../../hooks/useOpenDataset'
import IconButton from '../../../shared/IconButton'
import { ReactComponent as EditIcon } from '../../../../assets/Edit.svg'

const CardStyled = styled(Card)`
  padding: 20px;
  max-width: 1136px;
  margin: auto;
`

const TitleStyled = styled.span`
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin: 12px 0 24px;
  color: ${({ theme }) => theme.colors.black};
`

const SpinnerStyled = styled(Spinner)`
  margin-top: 20px;
`

const NavigationWrapStyled = styled.div`
    display: flex;
    justify-content: space-between;
`

const rows: TRow[] = [
  {
    Header: 'Dataset Name',
    accessor: 'name',
    Cell: ({ name, id }: any) => {
      const { to, state } = useOpenDataset(id)
      return <Link to={to} state={state}>{name}</Link>
    }
  },
  {
    Header: 'Dataset Short Name',
    accessor: 'short_name'
  },
  {
    Header: 'Host',
    accessor: 'host'
  },
  {
    Header: 'Other Affiliated Organizations',
    accessor: 'organizations'
  },
  {
    Header: 'Collection Mechanism',
    accessor: 'collection_mechanism'
  },
  {
    Header: 'Population',
    accessor: 'population'
  },
  {
    Header: 'Record Level',
    accessor: 'record_level'
  },
  {
    Header: 'Additional Weights',
    accessor: 'additional_weights'
  },
  {
    Header: 'Time Design',
    accessor: 'time_design'
  },
  {
    Header: 'Accessibility',
    accessor: 'accessibility'
  },
  {
    Header: 'Years of metadata included in Health Data Select',
    accessor: 'years_included'
  },
  {
    Header: 'Years data available from dataset host',
    accessor: 'years_available'
  },
  {
    Header: 'Current Periodicity',
    accessor: 'current_periodicity'
  },
  {
    Header: 'Lowest Geographic Level',
    accessor: 'lowest_geo_level'
  },
  {
    Header: 'Geographic Variables',
    accessor: 'geo_var'
  },
  {
    Header: 'Granularity',
    accessor: 'granularity'
  },
  {
    Header: 'Content Focus',
    accessor: 'content_focus'
  }
]

const DatasetsComparison = () => {
  const datasetsIds = useDatasetsIds()
  const navigate = useNavigate()
  const { data, isFetching } = useTableData(datasetsIds ?? [])

  useEffect(() => {
    if (!datasetsIds) {
      navigate(ROUTES.DATASETS)
    }
  }, [datasetsIds])

  return (
    <CardStyled>
      <NavigationWrapStyled>
        <BackLink link={ROUTES.DATASETS} pageName="Search results" />
        <IconButton
          onClick={() => navigate(ROUTES.DATASETS)}
          sx={{
            display: 'flex',
            gap: '8px',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '18.75px'
          }}
        >
          <EditIcon />
          Edit search
        </IconButton>
      </NavigationWrapStyled>

      <TitleStyled>Compare datasets</TitleStyled>
      {
        isFetching
          ? <SpinnerStyled />
          : (
            <DatasetDetailsTable
              data={data ?? []}
              rows={rows}
              sx={{
                '.MuiTableRow-root:first-of-type .MuiTableCell-root': {
                  paddingTop: 0
                }
              }}
            />
          )

      }
    </CardStyled>
  )
}

export default DatasetsComparison
